var render = function render(_c,_vm){return _c('div',{staticClass:"custom-radio__wrapper"},[_c('div',{class:[
      'custom-radio__option',
      _vm.props.hideinput === true ? 'input-hidden' : null,
      _vm.props.selected === 'left' ? 'selected' : null,
    ]},[_c('label',{staticClass:"custom-radio__label",attrs:{"for":_vm.props.labelleft.for}},[_vm._t("icon-left"),_vm._t("input-left"),_c('span',[_vm._v(_vm._s(_vm.props.labelleft.text))])],2)]),_c('div',{class:[
      'custom-radio__option',
      _vm.props.hideinput === true ? 'input-hidden' : null,
      _vm.props.selected === 'right' ? 'selected' : null,
    ]},[_c('label',{staticClass:"custom-radio__label",attrs:{"for":_vm.props.labelright.for}},[_vm._t("icon-right"),_vm._t("input-right"),_c('span',[_vm._v(_vm._s(_vm.props.labelright.text))])],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }