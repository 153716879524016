<template>
  <div>
    <DateAndTimeOperatorInput
      field-name="start"
      :label="t('start_date_label')"
      @input="typecast"
    />
    <DateAndTimeOperatorInput
      field-name="end"
      :label="t('end_date_label')"
      @input="typecast"
    />
  </div>
</template>

<script>
import DateAndTimeOperatorInput from "./DateAndTimeOperatorInput";

export default {
  name: "BetweenDatesOperatorInput",
  components: {
    DateAndTimeOperatorInput,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: "",
    },
  },
  data: function () {
    return {
      start: undefined,
      end: undefined,
    };
  },
  methods: {
    /**
     * Combines the values from both input controls, emits an object to `input` listeners once both values are present.
     */
    typecast: function (event) {
      this[event.target.id] = event.target.value;
      if (this.start && this.end) {
        this.$listeners.input({ start: this.start, end: this.end });
      }
    },
  },
  locales: {
    enGb: {
      start_date_label: "start date",
      end_date_label: "end date",
    },
    deDe: {
      start_date_label: "Startdatum",
      end_date_label: "Enddatum",
    },
    frFr: {
      start_date_label: "date de début",
      end_date_label: "date de fin",
    },
    esEs: {
      start_date_label: "fecha de inicio",
      end_date_label: "fecha final",
    },
    plPl: {
      start_date_label: "Data rozpoczęcia",
      end_date_label: "Data końcowa",
    },
    koKr: {
      start_date_label: "시작일",
      end_date_label: "종료일",
    },
  },
};
</script>

<style lang="scss">
.slider__label {
  text-align: center;
  margin-bottom: 1.5rem;
  padding: 0.5rem;
}
/* Set the theme color of the component */
$themeColor: $primary-color;
/* import theme style */
@import "~vue-slider-component/lib/theme/default.scss";
</style>
