<template>
  <section>
    <div v-for="sourceInstance in sourceInstances" :key="sourceInstance.id">
      <InstanceAssociationSourceInstance
        :source-instance="sourceInstance"
        :instance-association="getIAForSourceInstance(sourceInstance)"
        :group="group"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import InstanceAssociationSourceInstance from "@/components/instance-association/InstanceAssociationSourceInstance";

export default {
  name: "InstanceAssociationSource",
  components: {
    InstanceAssociationSourceInstance,
  },
  props: {
    source: {
      type: Object,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["language", "relatedEntity"]),
    sourceInstances: function () {
      return this.relatedEntity("sources", this.source.id, "sourceInstances");
    },
    instanceAssociations: function () {
      return this.$store.state.instanceAssociations;
    },
  },
  methods: {
    getIAForSourceInstance: function (si) {
      if (!this.instanceAssociations) return null;

      return Object.values(this.instanceAssociations)
        .filter((ia) => {
          return ia.relationships.sourceInstance.data.id === si.id;
        })
        .pop();
    },
  },
};
</script>
