<template>
  <div class="range-input">
    <VueSlider
      id="slider"
      :value="value"
      :min="min"
      :max="max"
      @change="triggerChange"
      tooltip="always"
      :interval="step"
      :contained="true"
      :lazy="true"
    />
    <label for="slider" class="slider__label">{{ label }}</label>
    <input
      ref="input"
      type="hidden"
      :value="value"
      :name="name"
      :id="name"
      :step="step"
    />
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";

/**
 * Numeric range input with a slider.
 */
export default {
  name: "RangeInputLabel",
  components: {
    VueSlider,
  },
  props: {
    /**
     * Current value
     */
    value: {
      type: [String, Number],
      required: true,
      default: "0",
    },
    /**
     * Minimum value
     */
    min: {
      type: Number,
      required: true,
      default: 0,
    },
    /**
     * Maximum value
     */
    max: {
      type: Number,
      required: true,
      default: 100,
    },
    step: {
      type: Number,
      default: 1,
    },
    /**
     * Form control label
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Input name, used for field's ID and `name` attributes. Configuration options will be saved with this field name.
     */
    name: {
      type: String,
      required: true,
      default: "rangeInput",
    },
  },
  methods: {
    triggerChange: function (val) {
      this.$refs.input.value = val;
      const event = new Event("change", { bubbles: true });
      this.$refs.input.dispatchEvent(event);
      this.$emit("change", event);
    },
  },
};
</script>

<style>
.range-input {
  height: 3.5rem;
  margin-top: 3rem;
}
</style>
