<template>
  <div class="webapp-hint__wrapper" :class="userAgent">
    <WebappHintiPhone v-if="userAgent === 'iphone'">
      <button type="button" class="webapp-hint__close" @click="closeHint">
        ×
      </button>
    </WebappHintiPhone>
    <WebappHintChromeAndroid v-else-if="userAgent === 'chrome-android'">
      <button type="button" class="webapp-hint__close" @click="closeHint">
        ×
      </button>
    </WebappHintChromeAndroid>
  </div>
</template>
<script>
import WebappHintiPhone from "@/components/ux/WebappHintiPhone.vue";
import WebappHintChromeAndroid from "@/components/ux/WebappHintChromeAndroid.vue";

export default {
  name: "WebappHint",
  components: {
    WebappHintiPhone,
    WebappHintChromeAndroid,
  },
  computed: {
    userAgent() {
      const ua = window.navigator.userAgent;
      if (ua.includes("iPhone")) return "iphone";
      if (ua.match(/(Android).*(Chrome)/g)) return "chrome-android";
      else return undefined;
    },
  },
  methods: {
    closeHint: function () {
      this.$store.commit("CHANGE_WEBAPPHINT_VISIBILITY", false);
    },
  },
};
</script>
<style lang="scss">
.webapp-hint__wrapper {
  position: absolute;
  z-index: 999;

  &.iphone {
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100vw;
  }
  &.chrome-android {
    right: 1.5vw;
    top: 0;
  }
}
</style>
