// @ts-check
/**
 * @file
 * Description what this does.
 */

// TODO: Change mutation names to uppercase convention, maybe add type constants later. See https://vuex.vuejs.org/guide/mutations.html#using-constants-for-mutation-types

import Vue from "vue";

/**
 * Toggle whether the user-provided input matches the defined admin password.
 * @param {Object} state
 * @param {Boolean} status
 */
export const CHANGE_PASSWORD_MATCH_STATUS = (state, status) => {
  state.passwordMatches = status;
};
/**
 * Toggle whether data is being fetched, e.g. to display a loading spinner.
 * @param {Object} state
 * @param {Boolean} status
 */
export const CHANGE_FETCH_STATUS = (state, status) => {
  state.fetching = status;
};

/**
 * Toggle whether the backend is running a blocking operation during which the user should not interfere (installation or reset).
 * @param {Object} state
 */
export const TOGGLE_BLOCKING_STATUS = (state) => {
  state.blocking = !state.blocking;
};

/**
 * Change the currently displayed route (URL hash component).
 * @param {Object} state
 * @param {String} route
 */
export const CHANGE_ROUTE = (state, route) => {
  state.route = Vue.set(state, "route", route);
};

/**
 * @param {Object} state
 * @param {String} widgetInstanceId
 */
export const CHANGE_CURRENT_WIDGET_INSTANCE = (state, widgetInstanceId) => {
  state.currentWidgetInstance = widgetInstanceId;
};

/**
 * @param {Object} state
 */
export const CLEAR_CURRENT_WIDGET_INSTANCE = (state) => {
  state.currentWidgetInstance = "";
};

/**
 * @param {Object} state
 * @param {Boolean} locked
 */
export const TOGGLE_GRIDLOCK = (state, locked) => {
  state.gridLocked = locked;
};

/**
 * @param {Object} state
 * @param {Object} gridSize
 */
export const SET_GRID_SIZE = (state, gridSize) => {
  state.gridSize = gridSize;
};

/**
 * @param {Object} state
 * @param {Object} payload
 */
export const SET_SYSTEMSTATUS = (state, payload) => {
  state.systemStatus = payload;
};

export const CHANGE_WEBAPPHINT_VISIBILITY = (state, payload) => {
  state.webappHintVisible = payload;
};

export const CHANGE_PAGESCROLL_STATE = (state, payload) => {
  state.pageScrolled = payload;
};

export const SET_BACKEND_RESTART_STATUS = (state, payload) => {
  state.backendRestarting = payload;
};

/**
 * Add error messages to the message stack.
 * @param {Object} state
 * @param {Array} errors
 */
export const ADD_ERRORS = (state, errors) => {
  state.errors = state.errors.concat(errors);
};

/**
 * Sets a global connectivity error e.g. if the server is unreachable.
 * @param {Object} state
 */
export const SET_NETWORK_ERROR = (state) => {
  state.networkError = "network error";
};

export const SET_CONFIGURATION_ERRORS = (state, errors) => {
  state.configurationErrors = errors;
};

/**
 * Removes the error at given index from the error stack.
 * @param {Object} state
 * @param {Number} errorIndex
 */
export const removeError = (state, errorIndex) => {
  state.errors.splice(errorIndex, 1);
};

/**
 * Clear all errors.
 * @param {Object} state
 */
export const clearErrors = (state) => {
  state.errors = [];
  state.networkError = [];
};

export const clearConfigurationErrors = (state) => {
  state.configurationErrors = [];
};

/**
 * Set the currently active notification.
 * @param {Object} state
 * @param {Number} notification
 */
export const ADD_NOTIFICATION = (state, notification) => {
  state.notifications.push(notification);
};

/**
 * Clear the currently active notification.
 * @param {Object} state
 */
export const CLEAR_OLDEST_NOTIFICATION = (state) => {
  state.notifications.shift();
};

// FIXME: init mutations can be unified with update mutations, see SET_<type> mutations.
/**
 * Initialize extension and instance data from a normalized JSON:API response.
 * @param {Object} state
 * @param {Object} payload
 */
export const SET_WIDGETS = (state, payload) => {
  state.widgets = { ...state.widgets, ...payload };
};

export const SET_WIDGETS_AVAILABLE = (state, payload) => {
  state.widgetsAvailable = { ...state.widgetsAvailable, ...payload };
};

export const SET_SOURCES = (state, payload) => {
  state.sources = { ...state.sources, ...payload };
};

export const SET_SOURCES_AVAILABLE = (state, payload) => {
  state.sourcesAvailable = { ...state.sourcesAvailable, ...payload };
};

export const SET_WIDGETINSTANCES = (state, payload) => {
  state.widgetInstances = { ...state.widgetInstances, ...payload };
};

export const SET_SOURCEINSTANCES = (state, payload) => {
  state.sourceInstances = { ...state.sourceInstances, ...payload };
};

export const SET_GROUPS = (state, payload) => {
  state.groups = { ...state.groups, ...payload };
};

export const SET_SETTINGS = (state, payload) => {
  state.settings = { ...state.settings, ...payload };
};

export const SET_INSTANCEASSOCIATIONS = (state, payload) => {
  state.instanceAssociations = payload; // REPLACE assocs to avoid stale data
};

export const UPDATE_INSTANCE_ASSOCIATIONS = (state, payload) => {
  state.instanceAssociations = { ...state.instanceAssociations, ...payload };
};

export const REMOVE_INSTANCEASSOCIATION = (state, payload) => {
  Vue.delete(state.instanceAssociations, payload.id);
};

export const CLEAR_INSTANCEASSOCIATIONS = (state) => {
  state.instanceAssociations = {};
};

/**
 * @param {Object} state
 * @param {{type: String, payload: Object}} args
 */
export const DELETE_EXTENSION_INSTANCE = (state, { type, payload }) => {
  Vue.delete(state[type], payload);
};

// FIME: install/update can be unified. Also, Extension type is not clearly defined.
/**
 * @typedef { import("../api/models").WidgetResource } Extension
 * @param {*} state
 * @param {*} extension
 */

/**
 * @param {Object} state
 * @param {Extension} extension
 */
export const INSTALL_EXTENSION = (state, extension) => {
  Vue.set(state[extension.type], extension.id, { ...extension });
};

/**
 * @param {Object} state
 * @param {Extension} extension
 */
export const UPDATE_EXTENSION = (state, extension) => {
  Vue.set(state[extension.type], extension.id, { ...extension });
};

/**
 * @param {Object} state
 * @param {Extension} extension
 */
export const UNINSTALL_EXTENSION = (state, extension) => {
  Vue.delete(state[extension.type], extension.id);
};

/**
 * @param {Object} state
 * @param {Extension} extension
 */
export const ENABLE_EXTENSION = (state, extension) => {
  state[extension.type][extension.id].attributes.active = true;
};

/**
 * @param {Object} state
 * @param {Extension} extension
 */
export const DISABLE_EXTENSION = (state, extension) => {
  state[extension.type][extension.id].attributes.active = false;
};

/**
 * Initialize board data from a normalized JSON:API response.
 * @param {Object} state
 * @param {Object} payload
 */
export const SET_BOARDS = (state, payload) => {
  state.boards = { ...state.boards, ...payload };
};

/**
 * Update board data from a normalized JSON:API response.
 * @param {Object} state
 * @param {Object} payload
 */
export const UPDATE_BOARDS = (state, payload) => {
  state.boards = { ...state.boards, ...payload };
};

/**
 * Remove board id from store.
 * @param {Object} state
 * @param {number|string} boardId
 */
export const DELETE_BOARD = (state, boardId) => {
  Vue.delete(state.boards, boardId);
};

export const SET_LOADED_BOARD = (state, boardId) => {
  state.loadedBoard = boardId;
};
