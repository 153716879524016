<template>
  <main class="setup-page">
    <section v-if="currentStep === 'step_language'" id="step_language">
      <h1>{{ t("Let’s get started") }}</h1>
      <p>
        {{
          t(
            "Thank you for using mirr.OS. We’ll guide you through the setup now."
          )
        }}
      </p>
      <br />
      <LanguageSelectionBlock
        :system_language="system_language"
        @formupdate="handleSettingUpdate"
      />
      <TimezoneSelectionBlock
        :system_timezone="system_timezone"
        @formupdate="handleSettingUpdate"
      />
      <button
        type="button"
        class="button expanded"
        :disabled="system_language.value === '' || system_timezone.value === ''"
        autocomplete="off"
        @click.prevent="setStep('step_privacy')"
      >
        {{ t("set up mirr.OS") }}
      </button>
      <button
        type="button"
        class="button clear expanded"
        @click.prevent="setStep('step_import')"
      >
        <em>{{ t("or import configuration") }}</em>
      </button>
    </section>

    <section v-if="currentStep === 'step_import'" id="step_import">
      <SetupHeader
        :stepper="stepper"
        :heading="t('restore from backup')"
        @prev-step="setStep(prevStep)"
      />
      <BackupRestoreBlock
        :errors="errors.BackupRestoreBlock"
        @input-validated="handleInputValidation"
        @clear-validations="handleValidationClearing"
      />
    </section>

    <section v-if="currentStep === 'step_privacy'" id="step_privacy">
      <SetupHeader
        :stepper="stepper"
        :heading="t('Privacy')"
        @prev-step="setStep(prevStep)"
      />

      <PrivacyConsentBlock
        :personal_privacyconsent="personal_privacyconsent"
        @formupdate="handleSettingUpdate"
      />
      <button
        type="button"
        class="button expanded button--shadow"
        :disabled="personal_privacyconsent.value !== 'yes'"
        @click.prevent="setStep('step_configure')"
      >
        {{ t("start setup") }}
      </button>
    </section>

    <section v-if="currentStep === 'step_configure'" id="step_configure">
      <SetupHeader
        :stepper="stepper"
        :heading="t('Network settings')"
        @prev-step="setStep(prevStep)"
      />
      <p>{{ t("Connect mirr.OS to your network.") }}</p>
      <form id="setup_form">
        <WLANSelectionBlock
          :network_connectiontype="network_connectiontype"
          :network_ssidinvisible="network_ssidinvisible"
          :network_ssid="network_ssid"
          :network_password="network_password"
          @formupdate="handleSettingUpdate"
          :system-status="systemStatus"
          @input-validated="handleInputValidation"
          @clear-validations="handleValidationClearing"
        />
        <RadioToggleBorderedLabel
          field-id="network_localmode"
          :text="t('local_network_mode_label')"
          :active="network_localmode.value === 'on'"
          @formupdate="handleSettingUpdate"
        />
        <details class="text--smaller text--margin-bottom">
          <summary class="text--blue">
            {{ t("local_network_mode_summary") }}
          </summary>
          <br />
          <p>
            {{ t("local_network_mode_help") }}
          </p>
        </details>

        <h5 class="text-left">
          {{ t("Send login data to") }}
        </h5>
        <PersonalSettingsBlock
          :personal_email="personal_email"
          :personal_name="personal_name"
          @formupdate="handleSettingUpdate"
          @input-validated="handleInputValidation"
        />

        <button
          type="submit"
          class="button expanded"
          @click.prevent="runSetup"
          :disabled="setupIncomplete"
        >
          {{ t("connect") }}
        </button>
      </form>
    </section>

    <section v-if="currentStep === 'step_finished'" id="step_finished">
      <h1>
        {{
          t(
            "Done. Now, please pay attention to your mirror and check your emails (including the spam folder)."
          )
        }}
      </h1>
    </section>
    <AnimatedLoader v-if="saving" />
  </main>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { SettingResource } from "@/api/models";
import { camelcase } from "@/api/helpers";

import axios from "axios";

import LanguageSelectionBlock from "@/components/config/LanguageSelectionBlock.vue";
import TimezoneSelectionBlock from "@/components/config/TimezoneSelectionBlock.vue";
import PrivacyConsentBlock from "@/components/config/PrivacyConsentBlock.vue";
import WLANSelectionBlock from "@/components/config/WLANSelectionBlock.vue";
import PersonalSettingsBlock from "@/components/config/PersonalSettingsBlock.vue";
import BackupRestoreBlock from "@/components/config/BackupRestoreBlock.vue";

import RadioToggleBorderedLabel from "@/components/forms/RadioToggleBorderedLabel.vue";
import SetupHeader from "@/components/SetupHeader.vue";
import AnimatedLoader from "@/components/AnimatedLoader.vue";

export default {
  // eslint-disable-next-line
  name: "PageSetup",
  components: {
    LanguageSelectionBlock,
    TimezoneSelectionBlock,
    PrivacyConsentBlock,
    WLANSelectionBlock,
    PersonalSettingsBlock,
    RadioToggleBorderedLabel,
    BackupRestoreBlock,
    SetupHeader,
    AnimatedLoader,
  },
  steps: ["language", "privacy", "configure", "finished"].map(
    (step) => `step_${step}`
  ),
  data: function () {
    return {
      errors: {
        WLANSelectionBlock: {},
        PersonalSettingsBlock: {},
        BackupRestoreBlock: {},
      },
      currentStep: "step_language",
      availableNetworks: [],
      saving: false,
    };
  },
  computed: {
    ...mapState(["systemStatus"]),
    ...mapGetters(["availableLanguages", "language"]),
    system_language: function () {
      return this.$store.state.settings.system_language.attributes;
    },
    system_timezone: function () {
      return this.$store.state.settings.system_timezone.attributes;
    },
    personal_privacyconsent: function () {
      return this.$store.state.settings.personal_privacyconsent.attributes;
    },
    personal_name: function () {
      return this.$store.state.settings.personal_name.attributes;
    },
    personal_email: function () {
      return this.$store.state.settings.personal_email.attributes;
    },
    network_localmode: function () {
      return this.$store.state.settings.network_localmode.attributes;
    },
    network_connectiontype: function () {
      return this.$store.state.settings.network_connectiontype.attributes;
    },
    network_ssidinvisible: function () {
      return this.$store.state.settings.network_ssidinvisible.attributes;
    },
    network_ssid: function () {
      return this.$store.state.settings.network_ssid.attributes;
    },
    network_password: function () {
      return this.$store.state.settings.network_password.attributes;
    },
    setupIncomplete: function () {
      let errorsPresent = 0;
      for (let component of Object.values(this.errors)) {
        for (let msg of Object.values(component)) {
          if (msg.length > 0) errorsPresent++;
        }
      }
      return errorsPresent > 0;
    },
    prevStep: function () {
      if (this.currentStep === "step_import") {
        return this.$options.steps[0];
      } else {
        return this.$options.steps[
          this.$options.steps.indexOf(this.currentStep) - 1
        ];
      }
    },
    stepper: function () {
      if (this.currentStep === "step_import") {
        return "2/2";
      } else {
        return `${this.$options.steps.indexOf(this.currentStep) + 1}/${
          this.$options.steps.length - 1
        }`;
      }
    },

    // TODO: Add computed properties for all required settings, returning empty defaults if not present yet.
  },
  beforeCreate: function () {
    // Initially, the backend sets no default locale.
    const mapping = {
      de: "deDe",
      en: "enGb",
      fr: "frFr",
      es: "esEs",
      pl: "plPl",
      ko: "koKr",
      // FIXME: Remove this once we have a unified translation file again.
    };
    const browserLanguage = camelcase(
      navigator.language || navigator.userLanguage
    );
    const langKey =
      browserLanguage.length === 2 ? mapping[browserLanguage] : browserLanguage;
    this.$translate.setLang(langKey);
  },
  methods: {
    setStep: function (step) {
      this.currentStep = step;
    },

    handleInputValidation: function ({ component, elname, msg }) {
      this.errors[component] = { ...this.errors[component], [elname]: msg };
    },
    handleValidationClearing: function ({ block, field }) {
      if (block && field) {
        this.errors[block][field] = "";
      } else {
        this.errors[block] = {};
      }
    },
    runSetup: async function () {
      // TODO: Validate form.
      this.setStep("step_finished");
      try {
        axios.post("/system/run_setup", { reference_time: Date.now() });
        this.$emit("setup-finished");
      } catch (error) {
        this.$store.commit("SET_ERROR", error);
      }
    },
    handleSettingUpdate: async function (e) {
      this.saving = true;
      await this.updateSetting(e.target.name, e.target.value);
      this.saving = false;
      //RETURN ALL FIELDS TO THIS HANDLER
    },
    /**
     * Updates a setting in the backend.
     *
     * @param {string} setting   The setting machine name
     * @param {string} value   The value to set
     */
    updateSetting: async function (setting, value) {
      let res = new SettingResource(setting);
      res.attributes.value = value;
      await this.$store.dispatch("updateSetting", { resource: res });
    },
  },
  locales: {
    enGb: {
      local_network_mode_label: "Local network mode",
      local_network_mode_summary: "What is the local network mode?",
      local_network_mode_help:
        "When you enable local network mode, mirr.OS will not attempt to connect to the internet. This also means that configuring widgets or data sources which require internet access will fail. Only enable this mode when you are certain that all data sources are available on your local network.",
    },
    deDe: {
      local_network_mode_label: "Lokaler Netzwerkmodus",
      local_network_mode_summary: "Was ist der lokale Netzwerkmodus?",
      local_network_mode_help:
        "Wenn Sie den lokalen Netzwerkmodus aktivieren, wird mirr.OS nicht versuchen, eine Verbindung zum Internet herzustellen. Das bedeutet auch, dass die Konfiguration von Widgets oder Datenquellen, die einen Internetzugang benötigen, fehlschlägt. Aktivieren Sie diesen Modus nur, wenn Sie sicher sind, dass alle Datenquellen in Ihrem lokalen Netzwerk verfügbar sind.",
    },
    frFr: {
      local_network_mode_label: "Local network mode",
      local_network_mode_summary: "Qu'est-ce que le mode réseau local?",
      local_network_mode_help:
        "Lorsque vous activez le mode réseau local, mirr.OS ne tentera pas de se connecter à Internet. Cela signifie également que la configuration des widgets ou des sources de données qui nécessitent un accès à Internet échouera. N'activez ce mode que si vous êtes certain que toutes les sources de données sont disponibles sur votre réseau local.",
    },
    esEs: {
      local_network_mode_label: "Modo de red local",
      local_network_mode_summary: "¿Cuál es el modo de red local?",
      local_network_mode_help:
        "Cuando se activa el modo de red local, mirr.OS no intentará conectarse a Internet. Esto también significa que la configuración de widgets o fuentes de datos que requieran acceso a Internet fallará. Sólo active este modo cuando esté seguro de que todas las fuentes de datos están disponibles en su red local.",
    },
    plPl: {
      local_network_mode_label: "Trybu sieci lokalnej",
      local_network_mode_summary: "Co to jest tryb sieci lokalnej?",
      local_network_mode_help:
        "Po włączeniu trybu sieci lokalnej mirr.OS nie będzie próbował łączyć się z internetem. Oznacza to również, że konfiguracja widżetów lub źródeł danych, które wymagają dostępu do Internetu, nie powiedzie się. Włącz ten tryb tylko wtedy, gdy jesteś pewien, że wszystkie źródła danych są dostępne w Twojej sieci lokalnej.",
    },
    koKr: {
      local_network_mode_label: "로컬 네트워크 모드",
      local_network_mode_summary: "로컬 네트워크 모드는 무엇인가요?",
      local_network_mode_help:
        "로컬 네트워크 모드를 활성화하면 mirr.OS는 인터넷 연결을 시도하지 않습니다. 이것은 또한 인터넷 액세스가 필요한 위젯 또는 데이터 소스 구성이 실패함을 의미합니다. 로컬 네트워크에서 모든 데이터 소스를 사용할 수 있다고 확신하는 경우에만 이 모드를 활성화하십시오.",
    },
  },
};
</script>
<style lang="scss">
#setup_form {
  text-align: left;
}

.setup-page {
  background-color: $white;
  margin: 0 auto;
  text-align: center;
  padding: 2rem 3rem;
  min-height: 100vh;
  width: 100%;

  @include breakpoint(medium) {
    border-top: 1px solid $thin;
    height: calc(100vh - 1.25rem);
    section {
      max-width: $global-width / 2;
      margin: 0 auto;
    }
  }
}
</style>
