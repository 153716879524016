<template>
  <details class="text-left text--info text--smaller" id="privacy-statement">
    <summary class="text--blue">
      {{ t("privacy statement") }}
      <span v-if="this.$translate.lang != 'deDe'">
        ({{ t("currently only in German") }})
      </span>
    </summary>
    <br />
    <p>
      Im Rahmen der Datenschutzgrundverordnung (DSGVO) informieren wir dich über
      den Datenaustausch des OpenSource-Betriebssystems mirr.OS.
    </p>
    <p>
      Du musst bei der Installation zustimmen, dass du folgende Informationen
      gelesen hast. Wenn du nicht einverstanden bist, kannst du mirr.OS nicht
      installieren.
    </p>

    <h6>Wer ist verantwortlich für die Datenerfassung von mirr.OS?</h6>
    <p>
      Die Datenverarbeitung von mirr.OS erfolgt durch den Websitebetreiber.
      Dessen Kontaktdaten kannst du dem Impressum der Website https://glancr.de
      entnehmen. Die Datenverarbeitung auf https://glancr.de erfolgt durch den
      Websitebetreiber. Alle Angaben findest du in der Datenschutzerklärung
      unter https://glancr.de/datenschutz/
    </p>

    <h6>Setup/Reset/Update-Info</h6>
    <p>
      Folgende Informationen werden beim initialen Setup/Reset und
      Update-Info-Prozessen an unseren API-Server übertragen:
    </p>
    <ul>
      <li>Name</li>
      <li>E-Mail-Adresse</li>
      <li>ob der Datenverarbeitung zugestimmt wurde</li>
    </ul>
    <p>
      Diese Informationen werden benötigt, um dir eine E-Mail mit deinen
      Login-Daten sowie Updatehinweise zu senden. Die Daten werden jedoch nicht
      auf unserem Server gespeichert.
    </p>

    <h6>Updates</h6>
    <p>
      mirr.OS überprüft in regelmäßigen Abständen, ob Updates vorhanden sind und
      lädt diese bei Bedarf automatisiert herunter. Dazu loggt der Webserver die
      anfragende IP-Adresse. Dies ist erforderlich, um die Datenübertragung zu
      gewährleisten.
    </p>

    <h6>Hosting</h6>
    <p>
      Unser API-Server wird von der Firma ALL-INKL.COM – Neue Medien Münnich
      (Inhaber: René Münnich), Hauptstraße 68, D-02742 Friedersdorf, gehostet.
      Die Server sind so eingestellt, dass keine Statistiken der Zugriffslogs
      (Accesslogs) erzeugt werden, keine Logfiles erstellt werden und bestehende
      Logfiles nach 7 Tagen automatisch gelöscht werden.
    </p>

    <h6>Abschluss eines Vertrags über Auftragsverarbeitung</h6>
    <p>
      Wir haben mit ALL-INKL.COM – Neue Medien Münnich einen Vertrag
      abgeschlossen, in dem wir ALL-INKL.COM – Neue Medien Münnich verpflichten,
      die Daten unserer User zu schützen und sie nicht an Dritte weiterzugeben.
      Diesen Vertrag senden wir gern auf Anfrage an kontakt@glancr.de zu.
    </p>

    <h6>Auskunft, Sperrung, Löschung und Berichtigung</h6>
    <p>
      Du hast im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das
      Recht auf unentgeltliche Auskunft über deine gespeicherten
      personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
      Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder
      Löschung dieser Daten. Hierzu, sowie zu weiteren Fragen zum Thema
      personenbezogene Daten, kannst du dich an die unter auf
      https://glancr.de/datenschutz/ angegebene Adresse wenden.
    </p>
  </details>
</template>
<script>
export default {
  name: "PrivacyStatementText",
};
</script>
