<template>
  <div>
    <FormControlToggle
      field-name="grid-locked"
      :active="!gridLocked"
      @change="toggleGridLock"
    >
      <template #icon>
        <SvgIcon
          icon-name="lock"
          color="var(--theme-color)"
          style="height: 1rem"
        />
      </template>
    </FormControlToggle>
  </div>
</template>

<script>
import FormControlToggle from "@/components/forms/FormControlToggle";
import { mapState } from "vuex";

export default {
  name: "GridControls",
  components: {
    FormControlToggle,
  },
  props: {
    editingBoardId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(["gridLocked", "settings", "boards"]),
    currentBoardName: function () {
      return this.boards[this.editingBoardId]?.attributes.title;
    },
  },
  methods: {
    /**
     * Toggles ability to move widgets around.
     */
    toggleGridLock: function () {
      this.$store.commit("TOGGLE_GRIDLOCK", !this.gridLocked);
    },
  },
};
</script>
