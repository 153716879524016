<template>
  <header class="site__header">
    <nav class="header__menu">
      <a
        v-for="menuItem in $options.menuItems"
        :key="menuItem.name"
        :href="menuItem.route"
        class="navigation__link"
        :class="{ active: isCurrentRoute(menuItem.route) }"
        @click.prevent="go(menuItem.route)"
      >
        <SvgIcon :icon-name="menuItem.name.toLowerCase()" />

        <span>{{ t(menuItem.name) }}</span>
      </a>
    </nav>
  </header>
</template>

<script>
import NavigationLinks from "@/mixins/NavigationLinks";

export default {
  name: "TheMainNavigation",
  mixins: [NavigationLinks],
  menuItems: [
    {
      route: "#board",
      name: "Board",
    },
    {
      route: "#widgets",
      name: "Widgets",
    },
    {
      route: "#sources",
      name: "Sources",
    },
    {
      route: "#configuration",
      name: "Settings",
    },
    {
      route: "#help",
      name: "Help",
    },
  ],
  methods: {
    isCurrentRoute: function (route) {
      return this.$store.state.route === route;
    },
  },
};
</script>

<style lang="scss">
.site__header {
  background-color: var(--theme-color);
  color: $white;
  z-index: 10;
}
.header__menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.header__menu a {
  color: white;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  flex-basis: 20%;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 0.95rem;
}

.navigation__link {
  box-sizing: border-box;
  margin-bottom: 2px;
  flex-grow: 1;
  &.active {
    border-bottom: 2px solid #58697c;
    margin-bottom: 0;
  }
  @include breakpoint(medium) {
    .icon__container {
      max-width: 20%;
    }
  }
  svg {
    margin-bottom: 0.25rem;
    max-height: 1.25rem;
    width: 1.25rem;
  }
}
</style>
