<template functional>
  <div class="custom-radio__wrapper">
    <div
      :class="[
        'custom-radio__option',
        props.hideinput === true ? 'input-hidden' : null,
        props.selected === 'left' ? 'selected' : null,
      ]"
    >
      <label :for="props.labelleft.for" class="custom-radio__label">
        <!-- @slot optional icon for the left-hand option -->
        <slot name="icon-left" />
        <!-- @slot slot for the left-hand input field -->
        <slot name="input-left" />
        <span>{{ props.labelleft.text }}</span>
      </label>
    </div>
    <div
      :class="[
        'custom-radio__option',
        props.hideinput === true ? 'input-hidden' : null,
        props.selected === 'right' ? 'selected' : null,
      ]"
    >
      <label :for="props.labelright.for" class="custom-radio__label">
        <!-- @slot optional icon for the right-hand option -->
        <slot name="icon-right" />
        <!-- @slot slot for the right-hand input field -->
        <slot name="input-right" />
        <span>{{ props.labelright.text }}</span>
      </label>
    </div>
  </div>
</template>
<script>
/**
 * Form control with separately labeled options, with an optional icon.
 */
export default {
  name: "RadioBoxedLabel",
  props: {
    /**
     * Label text and input element reference for the left-hand choice.
     */
    labelleft: {
      default: function () {
        return {
          for: "",
          text: "",
        };
      },
      type: Object,
      for: {
        type: String,
      },
      text: {
        type: String,
      },
    },
    /**
     * Label text and input element reference for the right-hand choice.
     */
    labelright: {
      default: function () {
        return {
          for: "",
          text: "",
        };
      },
      type: Object,
      for: {
        type: String,
      },
      text: {
        type: String,
      },
    },
    /**
     * Whether the `left` or `right` option is currently selected.
     */
    selected: {
      type: String,
      required: true,
      validator: function (value) {
        return ["left", "right"].includes(value);
      },
    },
    /**
     * If the native form input (radio circle) should be hidden. Useful if you pass icons.
     */
    hideinput: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.custom-radio__wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
}

/* Customize the label */
.custom-radio__option {
  margin-bottom: $global-margin;
  border: $input-border;
  border-radius: $input-radius;
  user-select: none;
  width: 45%;

  &.input-hidden {
    input {
      /* Hide the default input element */
      position: absolute;
      opacity: 0;
    }
  }

  &:not(.input-hidden) {
    display: flex;
    flex-direction: column;
    align-items: center;
    input {
      margin: 0.5rem 0 0 0;
    }
  }

  label {
    cursor: pointer;
    color: $medium-gray;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0 0.25rem 0;
  }
  /* On mouse-over, add a grey background color */
  &:hover {
    background-color: $light-color;
  }
  &.selected {
    border: 1px solid var(--theme-color);
    box-shadow: 0 0px 4px var(--theme-color);
    label {
      color: var(--theme-color);
    }
  }
}

.custom-radio__icon {
  width: 1.25rem;
}
</style>
