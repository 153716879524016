// Docs: https://networkmanager.dev/docs/api/latest/nm-dbus-types.html#NMConnectivityState
export const NmConnectivityState = Object.freeze({
  // Network connectivity is unknown.
  UNKNOWN: 0,
  // The host is not connected to any network.
  NONE: 1,
  // The host is behind a captive portal and cannot reach the full Internet.
  PORTAL: 2,
  // The host is connected to a network, but does not appear to be able to reach the full Internet.
  LIMITED: 3,
  // The host is connected to a network, and appears to be able to reach the full Internet.
  FULL: 4,
});
