<template>
  <main class="reconnect-page">
    <section>
      <h2>{{ t("Bring mirr.OS back online!") }}</h2>
      <p>{{ t("mirr.OS cannot connect to your network.") }}</p>

      <p v-if="settings.network_connectiontype.attributes.value === 'wlan'">
        {{
          t(
            "Please check your WiFi settings: Maybe there's a typo in the password? Please check if the correct WiFi name is selected, too."
          )
        }}
      </p>
      <p v-else-if="settings.network_connectiontype.attributes.value === 'lan'">
        {{
          t(
            "Please check your LAN connection: Is the cable properly plugged in? The green LED should be blinking to indicate network connectivity."
          )
        }}
      </p>
      <form id="network_settings">
        <WLANSelectionBlock
          :network_ssid="settings.network_ssid.attributes"
          :network_password="settings.network_password.attributes"
          :network_connectiontype="settings.network_connectiontype.attributes"
          :network_ssidinvisible="settings.network_ssidinvisible.attributes"
          @formupdate="handleSettingUpdate"
          :system-status="systemStatus"
        />

        <button
          class="button button--shadow"
          type="submit"
          @click.prevent="connectNetwork"
        >
          {{
            this.working["network_settings"] ? t("connecting") : t("connect")
          }}
        </button>
        <span v-if="this.successState['network_settings'] != null">
          {{ this.successState["network_settings"] ? "✓" : "X" }}
        </span>
      </form>
    </section>
    <section>
      <p class="mt-6">
        {{ t("Have you tried turning it off and on again?") }}
      </p>
      <button
        class="button button--shadow"
        type="submit"
        @click.prevent="triggerReboot"
      >
        {{ t("restart mirr.OS") }}
      </button>
    </section>
  </main>
</template>

<script>
import { mapState } from "vuex";
import { SettingResource } from "@/api/models";
import axios from "axios";

import WLANSelectionBlock from "@/components/config/WLANSelectionBlock";

export default {
  name: "PageReconnect",
  components: {
    WLANSelectionBlock,
  },
  data: () => {
    return {
      formDirty: {
        network_settings: false,
      },
      working: {
        network_settings: false,
      },
      successState: {
        network_settings: null,
      },
    };
  },
  computed: {
    ...mapState(["settings", "systemStatus"]),

    // FIXME: Add global responsive check instead.
    isMobile: function () {
      return window.innerWidth <= 480;
    },
  },
  created: async function () {
    return await this.$store.dispatch("fetchSettings");
  },
  methods: {
    handleSettingUpdate: function (e) {
      this.formDirty[e.target.form.id] = true;
      this.updateSetting(e.target.name, e.target.value);
    },
    updateSetting: function (setting, value) {
      let res = new SettingResource(setting);
      res.attributes.value = value;
      this.$store.dispatch("updateSetting", { resource: res });
    },
    applySetting: function (formId, endpoint) {
      this.formDirty[formId] = false;
      this.working[formId] = true;
      try {
        axios.get(endpoint).then((res) => {
          this.working[formId] = false;
          this.successState[formId] = res.data.success;
          setTimeout(() => {
            this.successState[formId] = null;
          }, 5000);
        });
      } catch (error) {
        this.successState[formId] = false;
      }
    },
    connectNetwork: function () {
      this.applySetting("network_settings", "/system/control/network/connect");

      this.successState["network_settings"] = true;
      setTimeout(() => {
        this.successState["network_settings"] = null;
      }, 5000);
    },
    triggerReboot: async function () {
      // TODO: Move to API module
      if (
        window.confirm(
          this.$translate.text("Do you really want to reboot mirr.OS?")
        )
      ) {
        try {
          let res = await axios.get("/system/reboot");
          this.$store.dispatch("handleNotification", {
            status: res.status,
            message: this.$translate.text("mirr.OS will reboot now"),
          });
          return res.status;
        } catch (error) {
          this.$store.dispatch("handleError", error);
        }
      }
    },
  },
  locales: {
    deDe: {
      "Have you tried turning it off and on again?":
        "Hast du versucht, das Gerät neu zu starten?",
    },
    frFr: {
      "Have you tried turning it off and on again?":
        "Avez-vous essayé de l'éteindre et rallumer?",
    },
    esEs: {
      "Have you tried turning it off and on again?":
        "¿Ha intentado apagarlo y encenderlo de nuevo?",
    },
    plPl: {
      "Have you tried turning it off and on again?":
        "Próbowałeś to wyłączyć i włączyć jeszcze raz?",
    },
    koKr: {
      "Have you tried turning it off and on again?": "껐다가 다시 켜보셨나요?",
    },
  },
};
</script>

<style lang="scss">
.reconnect-page {
  background-color: $white;
  margin: 0 auto;
  text-align: center;
  padding: 2rem 3rem;
  min-height: 100vh;

  @include breakpoint(medium) {
    border-top: 1px solid $thin;
    height: calc(100vh - 1.25rem);
    section {
      max-width: $global-width / 2;
      margin: 0 auto;
    }
  }
}
.setting legend {
  font-size: 1.5rem;
}

.mt-6 {
  margin-top: $global-margin * 2;
}
</style>
