<template>
  <main>
    <div class="extension-filter">
      {{ t("show") }}:
      <button
        v-for="filter in filters"
        :value="filter"
        :key="filter"
        @click="() => (currentFilter = filter)"
        :class="['button--slim', filter === currentFilter ? 'active' : null]"
      >
        {{ t(filter) }}
      </button>
    </div>
    <section
      class="grid-x grid-padding-x grid-padding-y small-up-1 medium-up-2 large-up-3"
    >
      <div v-for="(widget, id) in filteredWidgets" :key="id" class="cell">
        <ExtensionCard :extension="widget" :language="language" />
      </div>
    </section>
  </main>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ExtensionCard from "@/components/ExtensionCard.vue";

export default {
  name: "PageWidgets",
  components: {
    ExtensionCard,
  },
  data: () => {
    return {
      hover: false,
      currentFilter: "all",
      filters: ["all", "installed", "available"],
    };
  },
  computed: {
    ...mapState(["widgets"]),
    ...mapGetters(["language", "activeWidgets", "inactiveWidgets"]),
    filteredWidgets: function () {
      switch (this.currentFilter) {
        case "installed": {
          return this.activeWidgets;
        }
        case "available": {
          return this.inactiveWidgets;
        }
        case "all":
        default:
          return this.widgets;
      }
    },
  },
  beforeCreate: async function () {
    return await Promise.all([
      this.$store.dispatch("fetchWidgets"),
      this.$store.dispatch("fetchGroups"),
    ]);
  },
};
</script>
