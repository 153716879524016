<template>
  <section>
    <h6>{{ t("Usage of this source") }}</h6>
    <p>
      {{ t("Data from this source can be used by the following widgets.") }}
    </p>
    <ul class="widgetlist--inline">
      <li
        v-for="widget in compatibleWidgets"
        :key="widget.id"
        class="widgetlist--leftaligned"
      >
        <WidgetListItem :widget="widget" />
      </li>
    </ul>
    <SourceInstanceList :base-source="source.id" />

    <br />
    <br />
    <h6>{{ t("Privacy note") }}</h6>
    <p>
      {{
        t(
          "This source connects to the respective service. Please be aware that data might be exchanged, saved by or processed by the provider. In accordance with our philosophy, we do not save any of your data."
        )
      }}
    </p>
  </section>
</template>
<script>
import SourceInstanceList from "@/components/SourceInstanceList.vue";
import WidgetListItem from "@/components/WidgetListItem.vue";

export default {
  name: "SourceSettings",
  components: {
    SourceInstanceList,
    WidgetListItem,
  },
  props: {
    source: {
      type: Object,
      required: true,
    },
  },
  computed: {
    compatibleWidgets: function () {
      const groups = this.source.relationships.groups.data.map(
        (group) => group.id
      );

      let list = [];
      groups.forEach((group) => {
        const widgets = this.$store.state.groups[
          group
        ].relationships.widgets.data.map((ref) => {
          return this.$store.state.widgets[ref.id];
        });
        list.push(...widgets);
      });
      return list;
    },
  },
};
</script>
<style lang="scss">
.widgetlist--inline {
  list-style-type: none;
  margin-left: 0;
  display: flex;
}

.widgetlist--leftaligned {
  margin-right: 1rem;
}
</style>
