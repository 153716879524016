<template>
  <section v-on-clickaway="closeModal" class="settings__modal">
    <form @submit.prevent="$emit('save', $event.target)">
      <slot />
      <div class="instance__actions">
        <button
          type="button"
          class="button alert button--shadow"
          :disabled="removeDisabled"
          @click="emitRemoveAndClose"
        >
          {{ t("remove") }}
        </button>
        <button type="submit" class="button button--shadow">
          {{ t("save") }}
        </button>
      </div>
    </form>
  </section>
</template>
<script>
import { Bus as VuedalsBus } from "vuedals";
import { mixin as clickaway } from "vue-clickaway";

export default {
  name: "BaseModal",
  mixins: [clickaway],
  props: {
    removeDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    emitRemoveAndClose: function () {
      this.$emit("remove");
      this.closeModal();
    },
    closeModal: function () {
      VuedalsBus.$emit("close");
    },
  },
};
</script>
<style lang="scss">
.settings__modal {
  h5 {
    font-weight: 500;
  }
  fieldset {
    border-right: none;
    border-left: none;
    border-bottom: none;
  }
}
</style>
