<template>
  <div>
    <p class="text-left text--info text--smaller">
      {{
        t(
          "The information on the next page is required to set up mirr.OS and will be saved only on your local device."
        )
      }}
    </p>
    <p class="text-left text--info text--smaller">
      {{
        t(
          "After the setup, you will receive your login via e-mail. To send that e-mail, your e-mail address and name will be transmitted to our server. Nothing is stored permanently."
        )
      }}
      {{ t("For further details, see our") }}
    </p>
    <PrivacyStatementText />
    <br />

    <CheckboxBorderedLabel for="personal_privacyconsent" :text="t('I agree')">
      <input
        type="checkbox"
        required
        aria-required="true"
        name="personal_privacyconsent"
        id="personal_privacyconsent"
        :value="personal_privacyconsent.value"
        :checked="personal_privacyconsent.value === 'yes'"
        true-value="yes"
        false-value="no"
        @change="emitFormUpdate"
      />
    </CheckboxBorderedLabel>
  </div>
</template>

<script>
import PrivacyStatementText from "@/components/presentation/PrivacyStatementText";
import CheckboxBorderedLabel from "@/components/forms/CheckboxBorderedLabel";

export default {
  name: "PrivacyConsentBlock",
  components: {
    PrivacyStatementText,
    CheckboxBorderedLabel,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    personal_privacyconsent: {
      type: Object,
      required: true,
    },
  },
  methods: {
    emitFormUpdate: function (e) {
      // FIXME: This is a ugly hack, since somehow inbetween DOM, Vue and vuex, the event still (or again) holds the old value. This results in submitting the default "no" over and over.
      e.target.value = e.target.value === "no" ? "yes" : "no";
      this.$emit("formupdate", e);
    },
  },
};
</script>
<style lang="scss">
#privacy-statement {
  h6 {
    font-size: inherit;
    color: inherit;
  }
}
</style>
