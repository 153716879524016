<script>
const routes = {
  "#board": PageLayout,
  "#widgets": PageWidgets,
  "#sources": PageSources,
  "#configuration": PageSettings,
  "#help": PageHelp,
};

import PageLayout from "@/pages/PageLayout";
import PageWidgets from "@/pages/PageWidgets";
import PageSources from "@/pages/PageSources";
import PageSettings from "@/pages/PageSettings";
import PageHelp from "@/pages/PageHelp";

export default {
  name: "PageWrapper",
  computed: {
    ViewComponent() {
      return routes[this.currentRoute];
    },
    currentRoute() {
      return this.$store.state.route;
    },
  },
  render(h) {
    return h(this.ViewComponent);
  },
};
</script>
