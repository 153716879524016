<template>
  <VueSlider v-if="slider" @input="$listeners.input" />

  <TextInputFloatingLabel v-else :text="label" for="value">
    <input
      type="number"
      :min="min"
      :max="max"
      @input="typecast"
      id="value"
      name="value"
      placeholder="0-24"
    />
  </TextInputFloatingLabel>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

import TextInputFloatingLabel from "@/components/forms/TextInputFloatingLabel";

export default {
  name: "NumericOperatorInput",
  components: {
    VueSlider,
    TextInputFloatingLabel,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    min: {
      type: Number,
      required: false,
      default: undefined,
    },
    max: {
      type: Number,
      required: false,
      default: undefined,
    },
    slider: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    typecast: function (e) {
      const value = e.target.value;
      if (value >= this.min && value <= this.max) {
        this.$listeners.input(Number.parseInt(e.target.value));
      }
    },
  },
};
</script>

<style></style>
