<template>
  <TextInputFloatingLabel :text="label" :for="fieldName">
    <input
      type="datetime-local"
      :min="min"
      @input="(e) => $emit('input', e)"
      :id="fieldName"
      :name="fieldName"
      :placeholder="min"
      :required="required"
    />
  </TextInputFloatingLabel>
</template>

<script>
import TextInputFloatingLabel from "@/components/forms/TextInputFloatingLabel";

/**
 * Provides a datetime-local input with mirr.OS style wrappers.
 */
export default {
  name: "DateAndTimeOperatorInput",
  components: {
    TextInputFloatingLabel,
  },
  props: {
    /**
     * Label for the input field, will float above the cursor on focus.
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Assign input attributes `id` and `name`.
     */
    fieldName: {
      type: String,
      required: true,
    },
    /**
     * Minimum accepted date. See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local#min
     */
    min: {
      type: String,
      required: false,
      default: new Date().toISOString().slice(0, -8),
    },
    /**
     * Maximum accepted date. See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local#max
     */
    max: {
      type: String,
      required: false,
      default: undefined,
    },
    /**
     * Whether the field input is required.
     */
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style></style>
