<template>
  <form
    v-if="loaded"
    @submit.prevent="saveSourceInstanceSettings"
    :id="`settings-form-${component}${!instanceId ? '' : '-' + instanceId}`"
    class="sourcesettings__form"
  >
    <component
      :is="component"
      :current-settings="sourceInstanceSettings"
      :locale="language | bcp47tag"
    />
    <div v-if="instanceId" class="instance__actions">
      <button
        type="button"
        class="button alert button--shadow"
        @click="deleteSourceInstance"
      >
        {{ t("remove") }}
      </button>
      <button type="submit" class="button button--shadow" :disabled="saving">
        <AnimatedLoader v-if="saving" />
        <template v-else>
          {{ t("save") }}
        </template>
      </button>
    </div>
    <button v-else type="submit" class="button expanded" :disabled="saving">
      <AnimatedLoader v-if="saving" />
      <template v-else>
        {{ t("add") }}
      </template>
    </button>
  </form>
  <p v-else>
    {{ t("Loading source settings") }}
  </p>
</template>

<script>
import AnimatedLoader from "@/components/AnimatedLoader.vue";
import { api } from "@/api/operations";
import { SourceInstanceResource } from "@/api/models";
import { mapGetters } from "vuex";

export default {
  name: "SourceInstanceSettings",
  components: {
    AnimatedLoader,
  },
  props: {
    instanceId: {
      type: String,
      required: false,
      default: undefined,
    },
    baseSource: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      component: this.baseSource.replace("_", "-"),
      saving: false,
    };
  },
  computed: {
    ...mapGetters(["language"]),
    source: function () {
      return this.$store.state.sources[this.baseSource];
    },
    sourceInstanceSettings: function () {
      // FIXME: Too much logic to handle the case when there's no instance yet, refactor
      if (this.instanceId === undefined) return undefined;

      const instance = this.$store.state.sourceInstances[this.instanceId];
      return instance !== undefined
        ? instance.attributes.configuration
        : undefined;
    },
  },
  beforeMount: function () {
    // FIXME: Improve error logic in case backend cannot find the file
    this.$options.components[this.component] = api.templateAvailable(
      this.source.type,
      this.component,
      this.source.attributes.version
    );
    this.loaded = true;
  },
  methods: {
    saveSourceInstanceSettings: function (e) {
      this.saving = true;
      const formElement = document.getElementById(e.target.id);
      const formData = new FormData(formElement);

      let action;
      let options = {};

      if (this.instanceId != undefined) {
        options.id = this.instanceId;
        action = "updateExtensionInstance";
      } else {
        action = "initExtensionInstance";
        options.source = this.baseSource;
      }
      let resource = new SourceInstanceResource(options);

      // TODO: Extract to helper method for re-use in WidgetSettings
      let checkboxes = new Map();
      const booleans = ["on", "off", "true", "false"];
      for (let input of formElement.elements) {
        if (input.type === "checkbox" && booleans.includes(input.value)) {
          checkboxes.set(input.name);
        }
      }

      for (let [key, value] of formData) {
        if (checkboxes.has(key)) {
          // Checked boxes are set to true, overriding any value attribute for consistency.
          resource.addConfig(key, true);
          checkboxes.delete(key);
        } else {
          resource.addConfig(key, value);
        }
      }
      // The remaining checkbox fields are unchecked since they're not in the FormData entries. Set to false.
      for (let [key, value] of checkboxes) {
        value; // Stop eslint from complaining
        resource.addConfig(key, false);
      }

      this.$store
        .dispatch(action, {
          type: "source-instances",
          payload: resource,
        })
        .then(() => {
          // Re-fetch the source object with the new relation.
          // FIXME: Use the include param on the instance actions instead.
          this.$store.dispatch("fetchSource", {
            sourceId: this.baseSource,
          });
          this.saving = false;
        });
    },
    deleteSourceInstance: function () {
      this.$store
        .dispatch("deleteExtensionInstance", {
          type: "source-instances",
          payload: this.instanceId,
        })
        .then(() => {
          // Re-fetch the source object with the new relation.
          // FIXME: Use the include param on the instance actions instead.
          this.$store.dispatch("fetchSource", {
            sourceId: this.baseSource,
          });
        });
    },
  },
};
</script>

<style lang="scss">
.sourcesettings__form {
  display: flex;
  flex-direction: column;
}
</style>
