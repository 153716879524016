<template>
  <div
    :class="[
      'align-center',
      'notification__box',
      success ? 'success' : 'alert',
    ]"
  >
    <span class="notification__text">
      {{
        message != undefined
          ? message
          : success
          ? `${t("saved")}`
          : `${t("Error saving changes")}`
      }}
    </span>
    &nbsp;
    <span class="notification__icon">
      {{ success ? "✓" : "ｘ" }}
    </span>
  </div>
</template>
<script>
export default {
  name: "NotificationBubble",
  props: {
    success: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      required: false,
      default: undefined,
    },
  },
};
</script>
<style lang="scss">
.notification__box {
  background-color: $white;
  padding: 0.5rem 0.75rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  border: 1px solid var(--theme-color);
  border-top: none;
  border-bottom-left-radius: $global-radius;
  border-bottom-right-radius: $global-radius;
}
.alert .notification__icon {
  color: red;
}
.success .notification__icon {
  color: green;
}
</style>
