<template>
  <div class="webapp-hint">
    <p class="webapp-hint__content">
      <slot /> {{ t("Add mirr.OS to your home screen.") }} {{ t("Tap") }}
      <SvgIcon icon-name="ios-sharesheet" />
      {{ t("and then") }} <b>{{ t("Add to Home Screen") }}</b> &nbsp;
      <SvgIcon icon-name="add" />
    </p>
    <div class="webapp-hint__indicator" />
  </div>
</template>
<script>
export default {
  name: "WebappHintiPhone",
};
</script>
<style lang="scss" scoped>
.webapp-hint {
  max-width: 90vw;
}

.webapp-hint__content {
  padding: 1rem;
  border-radius: $global-radius;
  background-color: var(--theme-color);
  margin: 0;
  color: $white;
  position: relative;

  > svg {
    vertical-align: middle;
  }
}

.webapp-hint__close {
  position: absolute;
  top: 0;
  right: 0.5rem;
  color: $white;
  font-size: 2rem;
}

.webapp-hint__indicator {
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  position: relative;
  left: 37.5vw;
  border-top: 16px solid var(--theme-color);
}
</style>
