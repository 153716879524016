<template>
  <button
    class="button button--icon button--no-margin"
    @click="showBoardSettings"
  >
    <span>{{ t("Settings") }}</span
    >&nbsp;&nbsp;
    <SvgIcon icon-name="settings" />
  </button>
</template>

<script>
import { mapState } from "vuex";
import { Bus as VuedalsBus } from "vuedals";
import { mixin as clickaway } from "vue-clickaway";
import BoardSettings from "./BoardSettings.vue";

export default {
  name: "SingleBoardSettingsButton",
  mixins: [clickaway],
  props: {
    boardId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(["boards"]),
  },
  methods: {
    showBoardSettings: function () {
      VuedalsBus.$emit("new", {
        title: this.boards[this.boardId].attributes.title,
        name: `settings-modal-board-${this.boardId}`,
        component: BoardSettings,
        props: {
          boardId: this.boardId,
        },
        size: window.innerWidth <= 480 ? "xs" : "md", // FIXME: Determine from global settings once established.
      });
    },
  },
};
</script>
