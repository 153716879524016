<template>
  <fieldset>
    <div v-if="hasBackgroundImage" class="image-file">
      <button
        type="button"
        class="button image-file__delete-button"
        @click="detachCurrentBackgroundFromBoard"
      >
        {{ t("remove") }}
      </button>
      <img
        :src="backgroundUrl"
        alt="currently set background image"
        class="image-file__preview image-file__preview--constrained"
      />
    </div>
    <template v-else>
      <label for="backgroundImageFile" class="button">
        <AnimatedLoader v-if="backgroundImageUploading" />
        <span v-else>
          {{ t("Select background image") }}
        </span></label
      >
      <input
        type="file"
        id="backgroundImageFile"
        class="show-for-sr"
        name="upload[file]"
        accept="image/*"
        @change.prevent="uploadBackgroundImage"
        :disabled="backgroundImageUploading"
      />

      <template v-if="availableBackgrounds.length > 0">
        <p>
          {{ t("Or choose from your previous uploads") }}
        </p>
        <section class="grid">
          <div
            class="image-file"
            v-for="background in availableBackgrounds"
            :key="background.id"
          >
            <button
              type="button"
              class="button image-file__delete-button"
              @click="deleteBackgroundImage(background.id)"
            >
              {{ t("delete") }}
            </button>
            <img
              :src="background.file_url"
              alt="background image"
              class="image-file__preview"
              @click="setFromExisting(background)"
            />
          </div>
        </section>
      </template>
    </template>
    <input type="hidden" name="backgroundId" :value="backgroundId" />
  </fieldset>
</template>

<script>
import AnimatedLoader from "@/components/AnimatedLoader.vue";

export default {
  name: "BoardSettingsBackgroundImage",
  components: {
    AnimatedLoader,
  },
  data: function () {
    return {
      backgroundImageUploading: false,
      backgroundId: this.board.attributes.backgroundId,
      backgroundUrl: this.board.attributes.backgroundUrl,
      availableBackgrounds: [],
    };
  },
  props: {
    board: {
      type: Object,
      required: true,
    },
  },
  beforeMount: async function () {
    const res = await this.axios.get("/backgrounds");
    this.availableBackgrounds = res.data;
  },
  computed: {
    hasBackgroundImage: function () {
      return this.backgroundId ? true : false;
    },
  },
  methods: {
    setFromExisting: function (model) {
      this.backgroundId = model.id;
      this.backgroundUrl = model.file_url;
    },
    detachCurrentBackgroundFromBoard: function () {
      this.backgroundId = undefined;
      this.backgroundUrl = undefined;
    },
    uploadBackgroundImage: async function (e) {
      // TODO: Add error handling
      let fd = new FormData(e.target.form);
      for (let [key] of fd) {
        if (key !== "upload[file]") {
          fd.delete(key);
        }
      }
      this.backgroundImageUploading = true;
      const res = await this.axios.post("/backgrounds", fd);
      e.target.value = null;
      this.backgroundId = res.data.id;
      this.backgroundUrl = res.data.file_url;
      this.backgroundImageUploading = false;
      this.availableBackgrounds.push(res.data);
    },
    deleteBackgroundImage: async function (backgroundId) {
      // TODO: Error handling
      await this.axios.delete(`backgrounds/${backgroundId}`);
      this.availableBackgrounds = this.availableBackgrounds.filter(
        (model) => model.id !== backgroundId
      );
    },
  },
  locales: {
    deDe: {
      "Or choose from your previous uploads":
        "Oder wähle aus deinen vorherigen Uploads",
      delete: "löschen",
    },
    frFr: {
      delete: "supprimer",
    },
    esEs: {
      delete: "Eliminar",
    },
    plPl: {},
    koKr: {},
  },
};
</script>

<style lang="scss">
.grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  @include breakpoint(medium) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.image-file {
  position: relative;
}

.image-file__preview {
  margin: 0 auto;
  padding: 1rem;
  background-color: $light-gray;
  cursor: pointer;

  &:hover {
    box-shadow: $thumbnail-shadow;
  }
}

.image-file__preview--constrained {
  max-height: 16rem;
}

.image-file__delete-button {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 999;
}

.preview--fixed-dimensions {
  min-height: 10rem;
}
</style>
