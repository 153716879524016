<template>
  <main class="fullpage centered">
    <h3>{{ t("Secured area") }}</h3>
    <p>
      {{
        t(
          "Access to mirr.OS settings is password-protected. Please enter it to unlock the app."
        )
      }}
    </p>
    <form class="input-group" @submit.prevent="validatePassword">
      <TextInputFloatingLabel :for="passwordInput" :text="t('Password')">
        <input
          id="passwordInput"
          class="input-group-field"
          type="text"
          v-model="passwordInput"
          @focus="error = false"
        />
      </TextInputFloatingLabel>

      <div class="input-group-button">
        <input type="submit" class="button small" value="Login" />
      </div>
    </form>
    <span v-show="error" class="password__error">
      {{ t("wrong password") }}
    </span>
  </main>
</template>

<script>
import { mapState } from "vuex";
import ChecksPassword from "@/mixins/ChecksPassword";
import TextInputFloatingLabel from "@/components/forms/TextInputFloatingLabel";

export default {
  mixins: [ChecksPassword],
  components: {
    TextInputFloatingLabel,
  },
  data: function () {
    return {
      passwordInput: "",
      error: false,
    };
  },
  computed: {
    ...mapState(["settings"]),
  },
  methods: {
    validatePassword: function () {
      const passwordMatches =
        this.passwordInput ===
        this.rot13(this.settings.system_adminpassword?.attributes.value);
      this.error = !passwordMatches;
      this.updatePasswordMatchStatus(passwordMatches);
    },
  },
  locales: {
    deDe: {
      "Secured area": "Gesicherter Bereich",
      "Access to mirr.OS settings is password-protected. Please enter it to unlock the app.":
        "Der Zugriff auf die Einstellungen von mirr.OS ist kennwortgeschützt. Bitte gib das Passwort ein, um die App freizuschalten.",
      Login: "Anmeldung",
      "wrong password": "falsches Passwort",
    },
    frFr: {
      "Secured area": "Zone sécurisée",
      "Access to mirr.OS settings is password-protected. Please enter it to unlock the app.":
        "L'accès aux paramètres de mirr.OS est protégé par un mot de passe. Veuillez l'entrer pour déverrouiller l'application.",
      Login: "Connexion",
      "wrong password": "mauvais mot de passe",
    },
    esEs: {
      "Secured area": "Área asegurada",
      "Access to mirr.OS settings is password-protected. Please enter it to unlock the app.":
        "El acceso a la configuración del sistema operativo espejo está protegido por una contraseña. Por favor, introdúzcala para desbloquear la aplicación.",
      Login: "Ingresa en",
      "wrong password": "contraseña incorrecta",
    },
    plPl: {
      "Secured area": "Obszar zabezpieczony",
      "Access to mirr.OS settings is password-protected. Please enter it to unlock the app.":
        "Dostęp do ustawień mirr.OS jest chroniony hasłem. Wprowadź je, aby odblokować aplikację.",
      Login: "Login",
      "wrong password": "złe hasło",
    },
    koKr: {
      "Secured area": "안전한 지역",
      "Access to mirr.OS settings is password-protected. Please enter it to unlock the app.":
        "mirr.OS 설정에 대한 액세스는 비밀번호로 보호됩니다. 앱을 잠금 해제하려면 입력하십시오.",
      Login: "로그인",
      "wrong password": "잘못된 비밀번호",
    },
  },
};
</script>

<style scoped>
.password__error {
  color: red;
}
</style>
