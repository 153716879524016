<template>
  <div class="setupnav">
    <a class="setupnav__button" @click="$emit('prev-step')">
      <SvgIcon icon-name="arrow-back" class="text--light-gray" />
    </a>
    <h1>
      {{ heading }}
    </h1>
    <span class="setupnav__stepper">{{ stepper }}</span>
  </div>
</template>
<script>
export default {
  name: "SetupHeader",
  props: {
    heading: {
      type: String,
      required: true,
    },
    stepper: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.setupnav {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
  align-items: baseline;
}

.setupnav__button svg {
  height: 1rem;
}

.setupnav__stepper {
  color: $light-gray;
  font-size: #{map-get(
      map-get(map-get($header-styles, "small"), "h1"),
      "font-size"
    )}px;
}
</style>
