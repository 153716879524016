<template>
  <BaseModal @remove="remove">
    <!-- TODO: Save all three endpoints on submit instead of formChange -->
    <form
      @change.prevent="saveWidgetInstanceSettings"
      :id="`settings-form-${component}`"
      class="widgetsettings__form"
    >
      <h5>{{ t("Widget options") }}</h5>
      <component
        :is="component"
        :current-settings="widgetInstance.attributes.configuration"
        :backend-url="$root.$options.backendUrl"
        :locale="language | bcp47tag"
      />
      <ul>
        <li
          style="color: #c31432"
          v-for="(error, key) in configurationErrors"
          :key="key"
        >
          {{ error.title }}
        </li>
      </ul>
    </form>
    <div
      v-if="
        group != null &&
        (availableSources.length > 0 || instantiatedSources.length > 0)
      "
      class="widgetsettings__accounts"
    >
      <hr />
      <h5>{{ t("Accounts") }}</h5>

      <AnimatedLoader
        v-if="!loaded"
        classes="loader--centered"
        svg-classes="svg--blue"
      >
        {{ t("loading sources") }}
      </AnimatedLoader>

      <InstanceAssociationSource
        v-else
        v-for="source in instantiatedSources"
        :key="source.id"
        :source="source"
        :group="group"
      />
      <section v-show="availableSources.length > 0">
        <hr />
        <p>
          {{
            t(
              "These sources can also provide data for this widget – click to configure"
            )
          }}.
        </p>
        <ul class="widgetlist--inline">
          <li
            class="widgetlist--leftaligned"
            v-for="source in availableSources"
            :key="source.id"
          >
            <a href="#sources" @click.prevent="goToPage('sources')">
              <WidgetListItem :widget="source" />
              <!-- TODO: Deep-link to the source (e. g. by filtering) -->
            </a>
          </li>
        </ul>
      </section>
    </div>
    <hr />
    <h5>{{ t("Title") }}</h5>
    <form
      @change.prevent="saveWidgetInstanceMeta"
      :id="`meta-form-${widgetInstance.id}`"
    >
      <fieldset>
        <CheckboxBorderedLabel
          for="widgetmeta__showtitle"
          :text="t('show title')"
        >
          <input
            type="checkbox"
            id="widgetmeta__showtitle"
            name="showtitle"
            :checked="widgetInstance.attributes.showtitle"
            :true-value="true"
            :false-value="false"
          />
        </CheckboxBorderedLabel>
        <TextInputFloatingLabel
          for="widgetmeta__title"
          :text="t('custom title')"
        >
          <input
            type="text"
            name="title"
            id="widgetmeta__title"
            :value="widgetInstance.attributes.title"
            :placeholder="localisedAttribute(baseWidget, 'title', language)"
          />
        </TextInputFloatingLabel>
        <hr />
        <h5>{{ t("heading_design") }}</h5>

        <details>
          <summary class="text--blue text--margin-bottom">
            {{ t("label_design_summary") }}
          </summary>
          <fieldset name="styles">
            <div class="input-group">
              <TextInputFloatingLabel
                for="styles.fontColor"
                :text="t('Font color')"
              >
                <input
                  type="color"
                  placeholder="#000000"
                  id="styles.fontColor"
                  name="styles.fontColor"
                  class="input-group-field"
                  list="default-font-colors"
                  ref="fontColor"
                  :value="styles.fontColor"
                />
                <datalist id="default-font-colors">
                  <option value="#ffffff">
                    {{ t("white") }}
                  </option>
                  <option value="#000000">
                    {{ t("black") }}
                  </option>
                </datalist>
              </TextInputFloatingLabel>
              <div class="input-group-button">
                <button type="button" @click="removeFontColor" class="button">
                  {{ t("font_color_reset_label") }}
                </button>
              </div>
            </div>
            <TextInputFloatingLabel
              for="styles.fontSize"
              :text="t('font_size_label')"
            >
              <input
                id="styles.fontSize"
                type="number"
                pattern="\d*"
                step="10"
                name="styles.fontSize"
                :value="styles.fontSize"
                placeholder="100"
                min="100"
              />
            </TextInputFloatingLabel>
            <RadioToggleBorderedLabel
              :active="styles.backgroundBlur"
              field-id="styles.backgroundBlur"
              :text="t('blur_background_label')"
            />

            <label for="styles.horizontalAlign">
              {{ t("horizontal_align_label") }}</label
            >
            <select id="styles.horizontalAlign" name="styles.horizontalAlign">
              <option disabled selected value="">
                <em>{{ t("horizontal_align_dropdown_label") }}</em>
              </option>
              <option
                v-for="option in $options.horizontalAlignments"
                :key="option"
                :value="option"
                :selected="option === styles.horizontalAlign"
              >
                {{ t(option) }}
              </option>
            </select>

            <label for="styles.verticalAlign">{{
              t("vertical_align_label")
            }}</label>
            <select id="styles.verticalAlign" name="styles.verticalAlign">
              <option disabled selected value="">
                <em>{{ t("vertical_align_dropdown_label") }}</em>
              </option>
              <option
                v-for="option in $options.verticalAlignments"
                :key="option"
                :value="option"
                :selected="option === styles.verticalAlign"
              >
                {{ t(option) }}
              </option>
            </select>
          </fieldset>
        </details>
      </fieldset>
    </form>
  </BaseModal>
</template>

<script>
import { Bus as VuedalsBus } from "vuedals";
import { mixin as clickaway } from "vue-clickaway";
import BaseModal from "@/components/base/BaseModal";

import InstanceAssociationSource from "@/components/instance-association/InstanceAssociationSource";
import NavigationLinks from "@/mixins/NavigationLinks";
import AnimatedLoader from "@/components/AnimatedLoader.vue";
import WidgetListItem from "@/components/WidgetListItem.vue";

import localisedAttribute from "@/mixins/localisedAttribute";

import { WidgetInstanceResource } from "@/api/models";
import { mapState, mapGetters } from "vuex";
import { api } from "@/api/operations";
import TextInputFloatingLabel from "@/components/forms/TextInputFloatingLabel.vue";
import CheckboxBorderedLabel from "@/components/forms/CheckboxBorderedLabel.vue";
import RadioToggleBorderedLabel from "@/components/forms/RadioToggleBorderedLabel.vue";
import RadioBoxedLabel from "@/components/forms/RadioBoxedLabel.vue";

/**
 Gotchas: Since this component is loaded in a Vuedals modal, props are nested inside a props sub-property.
 */
export default {
  name: "WidgetSettings",
  mixins: [clickaway, NavigationLinks, localisedAttribute],
  components: {
    BaseModal,
    InstanceAssociationSource,
    AnimatedLoader,
    WidgetListItem,
    TextInputFloatingLabel,
    CheckboxBorderedLabel,
    RadioToggleBorderedLabel,
    RadioBoxedLabel,
  },
  horizontalAlignments: ["left", "right", "center", "justify"],
  verticalAlignments: ["top", "bottom", "center", "stretch"],
  data: function () {
    return {
      loaded: false,
      errors: [],
    };
  },
  computed: {
    ...mapState(["currentWidgetInstance", "configurationErrors"]),
    ...mapGetters([
      "language",
      "relatedEntity",
      "editingBoardId",
      "getSetting",
    ]),
    widgetInstance: function () {
      return this.$store.state.widgetInstances[this.currentWidgetInstance];
    },
    styles() {
      return this.widgetInstance.attributes.styles;
    },
    baseWidget: function () {
      return this.relatedEntity(
        "widgetInstances",
        this.widgetInstance.id,
        "widget"
      );
    },
    component: function () {
      return this.baseWidget.id.replace(/_/g, "-");
    },
    group: function () {
      return this.widgetInstance.relationships.group.data;
    },
    instantiatedSources: function () {
      if (this.group === null) return [];
      return this.relatedEntity("groups", this.group.id, "sources").filter(
        (source) => source.relationships.sourceInstances.data.length > 0
      );
    },
    availableSources: function () {
      if (this.group === null) return [];
      const sources = this.relatedEntity("groups", this.group.id, "sources");
      return sources.filter((source) => {
        return source.relationships.sourceInstances.data.length === 0;
      });
    },
    showtitleToggle: function () {
      return this.widgetInstance.attributes.showtitle;
    },
  },
  beforeMount: async function () {
    this.$options.components[this.component] = api.templateAvailable(
      this.baseWidget.type,
      this.component,
      this.baseWidget.attributes.version
    );
  },
  mounted: async function () {
    if (this.group != null) {
      Promise.all([
        this.$store.dispatch("fetchSubresource", {
          parentResource: this.widgetInstance.relationships.group.data.type,
          parentId: this.widgetInstance.relationships.group.data.id,
          childResource: "sources",
          includes: ["sourceInstances"],
        }),
        this.$store.dispatch(
          "fetchInstanceAssociationsForWidgetInstance",
          this.widgetInstance.id
        ),
      ]).then(() => {
        this.loaded = true;
      });
    }
  },
  beforeDestroy: function () {
    this.$store.commit("CLEAR_CURRENT_WIDGET_INSTANCE");
    this.$store.commit("CLEAR_INSTANCEASSOCIATIONS");
    this.$store.commit("clearConfigurationErrors");
  },
  methods: {
    removeFocus: function (e) {
      e.target.blur();
    },
    /**
     * Resets the fontColor attribute to the current system_fontcolor setting.
     */
    removeFontColor() {
      this.$refs.fontColor.value = this.getSetting("system_fontcolor");
      let el = document.getElementById("styles.fontColor");
      el.dispatchEvent(new Event("change", { bubbles: true }));
      el.blur();
    },
    // FIXME: Refactoring opportunity, saveWidgetInstanceMeta and saveWidgetInstanceSettings share a lot of code.
    /**
     * Saves the attribute settings for this widget instance.
     * @param {Event} e - The form event that triggered this function.
     */
    saveWidgetInstanceMeta: function (e) {
      const formElement = document.getElementById(e.target.form.id);
      const config = new FormData(formElement);
      let resource = new WidgetInstanceResource({ id: this.widgetInstance.id });

      // See saveWidgetInstanceSettings for rationale behind this block.
      let checkboxes = new Map();
      const booleans = ["on", "off", "true", "false"];

      for (let input of formElement.elements) {
        if (input.type === "checkbox" && booleans.includes(input.value)) {
          checkboxes.set(input.name, input.value);
        }
      }

      for (let [key, value] of config) {
        if (checkboxes.has(key)) {
          resource.setAttribute(key, true);
          checkboxes.delete(key);
        } else {
          resource.setAttribute(key, value);
        }
      }
      for (let [key] of checkboxes) {
        resource.setAttribute(key, false);
      }

      this.$store.dispatch("updateExtensionInstance", {
        type: "widget-instances",
        payload: resource,
      });
    },
    /**
     * Saves the widget-specific configuration for this instance.
     * @param {Event} e - the triggering form event
     */
    saveWidgetInstanceSettings: function (e) {
      this.$store.commit("clearConfigurationErrors");
      const formElement = document.forms[e.target.form.id];
      const config = new FormData(formElement);
      let resource = new WidgetInstanceResource({ id: this.widgetInstance.id });
      resource.attributes.configuration = {};

      // Workaround to save booleanish checkboxes properly.
      // TODO: Extract to helper method for re-use in SourceInstanceSettings
      let checkboxes = new Map();
      let hiddenInputs = new Map();
      const booleans = ["on", "off", "true", "false"];

      for (let input of formElement.elements) {
        // Only include toggle values: If the developer specifies a value attribute, we might be dealing with multiple checkboxes and the dev is aware that they need to handle this in their code.
        if (input.type === "checkbox" && booleans.includes(input.value)) {
          checkboxes.set(input.name, input.value);
        }
        if (["file", "submit"].includes(input.type)) {
          // TODO: This removes file upload fields from the FormData object since they need to be handled separately.
          config.delete(input.name);
        }
        if (input.type === "hidden" && booleans.includes(input.value)) {
          hiddenInputs.set(input.name, input.value);
        }
      }

      for (let [key, value] of config.entries()) {
        if (checkboxes.has(key)) {
          // Checked boxes are set to true, overriding any value attribute for consistency.
          resource.addConfig(key, true);
          checkboxes.delete(key);
        } else if (hiddenInputs.has(key)) {
          resource.addConfig(key, hiddenInputs.get(key) === "true");
          hiddenInputs.delete(key);
        } else {
          resource.addConfig(key, value);
        }
      }
      // The remaining checkbox fields are unchecked since they're not in the FormData entries. Set to false. Checkboxes with non-booleanish values are not included in `checkboxes`.
      for (let key of checkboxes.keys()) {
        resource.addConfig(key, false);
      }
      this.$store.dispatch("updateExtensionInstance", {
        type: "widget-instances",
        payload: resource,
      });
    },
    /**
     * Navigates to another page and closes the settings modal.
     * @param {string} page - The page to go to.
     */
    goToPage: function (page) {
      this.go(`#${page}`);
      this.closeModal();
    },
    closeModal: function () {
      VuedalsBus.$emit("close");
    },
    remove: function () {
      this.$store.dispatch("deleteWidgetInstanceFromBoard", {
        boardId: this.editingBoardId,
        widgetInstanceId: this.widgetInstance.id,
      });

      this.closeModal();
    },
  },
  locales: {
    enGb: {
      heading_design: "Design options",
      label_design_summary: "show options",
      font_color_reset_label: "reset",
      font_size_label: "Font size in %",
      blur_background_label: "background with frosted glass effect",
      horizontal_align_label: "Horizontal text alignment",
      horizontal_align_dropdown_label: "Select horizontal text alignment",
      vertical_align_label: "Vertical text alignment",
      vertical_align_dropdown_label: "Select vertical text alignment",
    },
    deDe: {
      heading_design: "Design-Optionen",
      label_design_summary: "Optionen anzeigen",
      font_color_reset_label: "zurücksetzen",
      font_size_label: "Schriftgröße in %",
      blur_background_label: "Mattglas-Effekt im Hintergrund",
      horizontal_align_label: "Horizontale Textausrichtung",
      horizontal_align_dropdown_label: "Horizontale Textausrichtung wählen",
      vertical_align_label: "Vertikale Textausrichtung",
      vertical_align_dropdown_label: "Vertikale Textausrichtung wählen",
      left: "links",
      right: "rechts",
      center: "zentriert",
      justify: "Blocksatz",
      top: "oben",
      bottom: "unten",
      stretch: "strecken",
    },
    frFr: {
      heading_design: "Options de conception",
      label_design_summary: "Afficher les options",
      font_color_reset_label: "reset",
      font_size_label: "Échelle de taille des caractères en %",
      blur_background_label: "fond de widget flou",
      horizontal_align_label: "Alignement horizontal du contenu",
      horizontal_align_dropdown_label: "Sélectionnez l'alignement horizontal",
      vertical_align_label: "Alignement vertical du contenu",
      vertical_align_dropdown_label: "Sélectionnez l'alignement vertical",
      left: "à gauche",
      right: "à droite",
      center: "centre",
      justify: "justifié",
      top: "haut",
      bottom: "bas",
      stretch: "s'étirer",
    },
    esEs: {
      heading_design: "Opciones de diseño",
      label_design_summary: "opciones",
      font_color_reset_label: "reiniciar",
      font_size_label: "Escala de tamaño de letra en %",
      blur_background_label: "Desenfocar el fondo del widget",
      horizontal_align_label: "Alineación horizontal del contenido",
      horizontal_align_dropdown_label: "Seleccionar alineación horizontal",
      vertical_align_label: "Alineación vertical del contenido",
      vertical_align_dropdown_label: "Seleccionar alineación vertical",
      left: "a la izquierda",
      right: "a la derecha",
      center: "centro",
      justify: "justificado",
      top: "cima",
      bottom: "fondo",
      stretch: "tramo",
    },
    plPl: {
      heading_design: "Opcje projektowania",
      label_design_summary: "Pokaż opcje",
      font_color_reset_label: "resetowanie",
      font_size_label: "Skala wielkości czcionki w %",
      blur_background_label: "rozmycie tła widgetu",
      horizontal_align_label: "Poziome wyrównanie treści",
      horizontal_align_dropdown_label: "Wybierz wyrównanie w poziomie",
      vertical_align_label: "Wyrównanie treści w pionie",
      vertical_align_dropdown_label: "Wybierz wyrównanie w pionie",
      left: "lewa",
      right: "w prawo",
      center: "centrum",
      justify: "uzasadniony",
      top: "top",
      bottom: "dolny",
      stretch: "rozciągać",
    },
    koKr: {
      heading_design: "디자인 옵션",
      label_design_summary: "쇼 옵션",
      font_color_reset_label: "초기화",
      font_size_label: "글꼴 크기 비율 %",
      blur_background_label: "위젯 배경 흐림",
      horizontal_align_label: "콘텐츠의 수평 정렬",
      horizontal_align_dropdown_label: "수평 정렬 선택",
      vertical_align_label: "콘텐츠의 수직 정렬",
      vertical_align_dropdown_label: "수직 정렬 선택",
      left: "왼쪽",
      right: "권리",
      center: "센터",
      justify: "양쪽 맞춤 텍스트",
      top: "상단",
      bottom: "바닥",
      stretch: "뻗기",
    },
  },
};
</script>

<style lang="scss">
.widgetsettings__form {
  display: flex;
  flex-direction: column;
}

.widgetsettings__accounts {
  margin-bottom: 1rem;
}

.settings__modal {
  h5 {
    font-weight: 500;
  }
  fieldset {
    border-right: none;
    border-left: none;
    border-bottom: none;
  }
}
</style>
