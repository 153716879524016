<template>
  <span>{{ t("Error while loading") }}</span>
</template>

<script>
export default {
  name: "FetchError",
  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>
