<template>
  <form @change="saveInstanceAssociation" :id="`ia_form_${sourceInstance.id}`">
    <fieldset>
      <!-- <legend>{{ sourceInstance.attributes.title }}</legend> -->

      <div
        v-for="(option, idx) in sourceInstance.attributes.options"
        :key="option.uid"
      >
        <input
          type="checkbox"
          :id="`chosen-${sourceInstance.id}-${idx}`"
          :checked="
            instanceAssociation &&
            instanceAssociation.attributes.configuration.chosen.includes(
              option.uid.toString()
            )
          "
          :value="option.uid"
          name="chosen"
        />
        <i v-html="widgetIcon" class="icon--inline" />
        <label class="ia__label" :for="`chosen-${sourceInstance.id}-${idx}`">{{
          option.display
        }}</label>
      </div>
    </fieldset>
  </form>
</template>

<script>
import { InstanceAssociationResource } from "@/api/models";
import { api } from "@/api/operations";

export default {
  name: "InstanceAssociationSourceInstance",
  props: {
    sourceInstance: {
      type: Object,
      required: true,
    },
    instanceAssociation: {
      type: Object,
      required: false,
      default: null,
    },
    group: {
      type: Object,
      required: true,
    },
  },
  computed: {
    source: function () {
      return this.$store.state.sources[
        this.sourceInstance.relationships.source.data.id
      ];
    },
  },
  asyncComputed: {
    widgetIcon: async function () {
      return await api.getExtensionAsset({
        extType: this.source.type,
        extension: this.source.id,
        assetType: "icons",
        filename: `${this.source.id}.svg?${this.source.attributes.version}`,
      });
    },
  },
  methods: {
    saveInstanceAssociation: function (e) {
      const config = new FormData(document.getElementById(e.target.form.id));
      let options = {};

      if (this.instanceAssociation) {
        options.id = this.instanceAssociation.id;
      } else {
        options.relationships = {
          sourceInstance: this.sourceInstance.id,
          widgetInstance: this.$store.state.currentWidgetInstance,
          group: this.group.id,
        };
      }
      let resource = new InstanceAssociationResource(options);

      // eslint-disable-next-line
      for (let [key, value] of config.entries()) {
        resource.attributes.configuration.chosen.push(value);
      }

      if (resource.attributes.configuration.chosen.length === 0) {
        this.$store.dispatch("deleteInstanceAssociation", resource);
        return;
      }

      if (!this.instanceAssociation) {
        this.$store.dispatch("createInstanceAssociation", resource);
      } else {
        this.$store.dispatch("updateInstanceAssociation", resource);
      }
    },
  },
};
</script>
<style lang="scss">
.ia__label {
  display: inline-block;
}
</style>
