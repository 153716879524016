<template functional>
  <div :class="props.classes">
    <svg
      :class="props.svgClasses"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 52 20"
      enable-background="new 255 255 255 0"
      xml:space="preserve"
    >
      <circle fill="currentColor" stroke="none" cx="6" cy="10" r="6">
        <animate
          attributeName="opacity"
          dur="2s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.1"
        />
      </circle>
      <circle fill="currentColor" stroke="none" cx="26" cy="10" r="6">
        <animate
          attributeName="opacity"
          dur="2s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.2"
        />
      </circle>
      <circle fill="currentColor" stroke="none" cx="46" cy="10" r="6">
        <animate
          attributeName="opacity"
          dur="2s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.3"
        />
      </circle>
    </svg>
    <slot />
  </div>
</template>
<script>
export default {
  name: "AnimatedLoader",
  props: {
    classes: {
      type: String,
      required: false,
      default: "loader--default",
    },
    svgClasses: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
<style lang="scss">
.loader--centered {
  max-width: 25%;
  margin: 0 auto;
  text-align: center;
}

.loader--default {
  width: 2rem;
  margin: 0 auto;
}
</style>
