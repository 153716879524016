<template>
  <label for="slider" class="slider__label"
    >{{ label }}
    <VueSlider
      id="slider"
      @change="typecast"
      :value="[min, max]"
      :min="min"
      :max="max"
      tooltip="always"
      :enable-cross="false"
      :min-range="1"
      :contained="true"
    />
  </label>
</template>

<script>
import VueSlider from "vue-slider-component";

export default {
  name: "RangeOperatorInput",
  components: {
    VueSlider,
  },
  props: {
    min: {
      type: Number,
      required: true,
      default: 0,
    },
    max: {
      type: Number,
      required: true,
      default: 24,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
  },
  methods: {
    typecast: function (val) {
      this.$listeners.input({ start: val[0], end: val[1] });
    },
  },
};
</script>

<style lang="scss">
.slider__label {
  text-align: center;
  margin-bottom: 1.5rem;
  padding: 0.5rem;
}
/* Set the theme color of the component */
$themeColor: $primary-color;
/* import theme style */
@import "~vue-slider-component/lib/theme/default.scss";
</style>
