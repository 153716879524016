<template>
  <div class="toggle__wrapper">
    <span class="toggle__label">{{ text }}</span>
    <FormControlToggle
      ref="control"
      :field-name="fieldId"
      :active="active"
      @change="(e) => $emit('formupdate', e)"
    />
  </div>
</template>
<script>
/**
 * Toggle form control with a label.
 */
import FormControlToggle from "@/components/forms/FormControlToggle";

export default {
  name: "RadioToggleBorderedLabel",
  components: {
    FormControlToggle,
  },
  props: {
    fieldId: {
      type: String,
      required: true,
      default: "",
    },
    text: {
      type: String,
      required: true,
      default: "",
    },
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.toggle__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: $input-border;
  border-radius: $global-radius;
  height: 3.5rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
}
</style>
