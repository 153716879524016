<template functional>
  <div class="floating-label" :class="{ 'has-hint': props.hint.length > 0 }">
    <slot />
    <label :for="props.for"> {{ props.text }} </label>
    <small class="hint" v-html="props.hint" />
  </div>
</template>
<script>
/**
 * Text input with an inline floating label.
 */
export default {
  name: "TextInputFloatingLabel",
  props: {
    /**
     * The label text
     */
    text: {
      type: String,
      required: true,
    },
    /**
     * The ID of the input element passed to the default slot (see example).
     */
    for: {
      type: String,
      required: true,
    },
    /**
     * Optional explainer text for this field, permanently shown below the input. This is rendered via the v-html directive, so you may pass any valid HTML string here.
     */
    hint: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
<style lang="scss">
.floating-label {
  position: relative;
  width: inherit;

  input {
    height: 3.5rem;
  }

  textarea {
    &:focus,
    &:not(:placeholder-shown) {
      height: initial;
      // TODO: Somehow prevent height increase by padding …
    }
  }

  label {
    color: $medium-gray;
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
    font-size: 1rem;
    line-height: 2.5rem;
  }

  input,
  textarea {
    /*
    Disable the default placeholder.
    IMPORTANT: Placeholder attribute still required so that
    :placeholder-shown rules work
    */
    &::placeholder {
      color: transparent;
    }

    &:focus {
      &::placeholder {
        color: $medium-gray;
        transition: color 1s;
      }
    }

    &:focus,
    &:not(:placeholder-shown) {
      padding-top: 1.5rem;

      & ~ label {
        color: var(--theme-color);
        font-size: 0.75rem;
        line-height: 1rem;
        top: 0.5rem;
        transition: all 0.5s;
      }
    }
  }

  &.has-hint {
    margin-bottom: 1rem;

    & input {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
