<template>
  <select id="system_language" name="system_language" @change="emitFormUpdate">
    <option disabled selected value="">
      <em>{{ t("Select your language") }}</em>
    </option>
    <option
      v-for="(language, key) in system_language.options"
      :key="key"
      :value="key"
      :selected="key === system_language.value"
    >
      {{ language }}
    </option>
  </select>
</template>
<script>
export default {
  name: "LanguageSelectionBlock",
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    system_language: {
      type: Object,
      required: true,
    },
  },
  methods: {
    emitFormUpdate: function (e) {
      this.$emit("formupdate", e);
    },
  },
};
</script>
