<template>
  <li class="board-list__list-item" :class="{ active: active, loaded: loaded }">
    <button @click="$emit('load-board', board.id)" style="cursor: pointer">
      {{ board.attributes.title }}
    </button>
    <span>
      <SvgIcon icon-name="activate" v-if="active" />
      <button @click="$emit('show-settings', board.id)" style="cursor: pointer">
        <SvgIcon icon-name="settings" />
      </button>
    </span>
  </li>
</template>

<script>
export default {
  name: "BoardSelectorListItem",
  props: {
    board: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    loaded: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.board-list__list-item {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  box-shadow: inset 0 -1px 0 0 rgba(221, 221, 221, 0.5);
  color: $medium-dark-gray;
  &.loaded {
    color: var(--theme-color);
  }

  &.active,
  // Since the loaded board might be active, this ensures that `active` styling always has higher precedence.
  .loaded.active {
    color: $secondary-color;
  }
}

.board-list__list-item:hover,
.board-list__list-item:hover > button {
  color: $primary-color;
}
</style>
