/**
 * @file
 * Description what this does.
 */

import { NmConnectivityState } from "@/api/constants";

export const language = (state) => {
  return state.settings.system_language != undefined
    ? state.settings.system_language.attributes.value
    : "enGb";
};

export const ap_active = (state) => {
  if (state.systemStatus.primary_connection != null) {
    return (
      state.systemStatus.primary_connection.connection_id === "glancrsetup"
    );
  } else {
    return false;
  }
};

/**
 * Returns the configured board orientation.
 * @param {object} state
 * @returns {string} `landscape` or `portrait`
 */
export const boardOrientation = (state) => {
  return state.systemStatus?.client_display?.orientation || "portrait";
};

/**
 * Checks if the user has enabled password protection and a password is set (empty string does not count as a valid password).
 *
 * @param {Object} state The current store state
 * @returns {boolean} Whether password protection is enabled.
 */
export const passwordProtectionEnabled = (state) => {
  return (
    (state.settings.system_passwordprotection?.attributes.value === "on" &&
      state.settings.system_adminpassword?.attributes.value?.length > 0) ??
    false
  );
};

export const multipleBoardsEnabled = (state) => {
  return state.settings.system_multipleboards.attributes.value === "on";
};

export const boardRotationEnabled = (state) => {
  return state.settings.system_boardrotation.attributes.value === "on";
};

export const activeBoardId = (state) => {
  return state.settings.system_activeboard.attributes.value;
};

export const editingBoardId = (state, getters) => {
  if (getters.multipleBoardsEnabled === false) {
    return getters.activeBoardId;
  }
  return state.loadedBoard ?? getters.activeBoardId;
};

export const widgetInstanceIdsForBoardId = (state) => (boardId) => {
  return (
    state.boards[boardId]?.relationships.widgetInstances.data.map(
      (wi) => wi.id
    ) ?? []
  );
};

export const systemOffline = (state, getters) => {
  if (state.systemStatus.connectivity_check_available) {
    return state.systemStatus.connectivity <= NmConnectivityState.PORTAL;
  } else {
    return (
      state.online === false &&
      state.primaryConnection != null &&
      getters.ap_active === false
    );
  }
};

export const primaryConnectionIP = (state) => {
  return state.systemStatus.network?.primary_connection?.ip4_address;
};

export const availableLanguages = (state) => {
  return state.settings.system_language != undefined
    ? state.settings.system_language.attributes.options
    : {};
};

export const getSetting = (state) => (setting) => {
  return state.settings[setting].attributes.value;
};

export const resourceIdsForType = (state) => (slice) => {
  return Object.keys(state[slice]);
};

/**
 * Returns a map of currently active
 * @param {string} type The extension type in plural form. Valid values are `widgets` and `sources`.
 */
export const activeExtensions = (state) => (type) => {
  // Use ES6 argument destructuring for reduce's `current` parameter.
  return Object.entries(state[type]).reduce((acc, [name, extension]) => {
    if (extension.attributes.active) {
      acc[name] = extension;
    }
    return acc;
  }, {});
};

export const activeSources = (state, getters) => {
  return getters.activeExtensions("sources");
};

export const activeWidgets = (state, getters) => {
  return getters.activeExtensions("widgets");
};

export const inactiveExtensions = (state) => (type) => {
  return Object.values(state[type]).filter((ext) => !ext.attributes.active);
};

export const inactiveSources = (state, getters) => {
  return getters.inactiveExtensions("sources");
};

export const inactiveWidgets = (state, getters) => {
  return getters.inactiveExtensions("widgets");
};

/**
 * Get a store entity referenced by JSON:API relationship.
 * @param state
 */
export const relatedEntity = (state) => (entityType, entityId, relation) => {
  const entityData = state[entityType][entityId].relationships[relation].data;

  // Somehow, the relation data is not present. Avoid returning `undefined`.
  if (entityData === undefined) return {};

  if (Array.isArray(entityData)) {
    // We have a 1:n relation.
    return entityData
      .map((entity) => {
        return state[entity.type][entity.id];
      }) // If the source instances are not yet loaded, ensure that we don't pass `undefined`.
      .filter((entity) => entity != undefined);
  } else {
    // 1:1 relation.
    return state[entityData.type][entityData.id];
  }
};

export const widgetInstanceSettings = (state) => (instanceId) => {
  return Object.assign(
    {},
    state.widgetInstances[instanceId].attributes.configuration
  );
};
export const widgetIdForInstanceId = (state) => (instanceId) => {
  return state.widgetInstances[instanceId].relationships.widget.data.id;
};
