import Vue from "vue";
import App from "./App.vue";
import store from "./store";

import axios from "axios";
import VueAxios from "vue-axios";
import httpVueLoader from "http-vue-loader";

import VueTranslate from "vue-translate-plugin";
import Vuedals from "vuedals";
import AsyncComputed from "vue-async-computed";
import { validate } from "@/directives/validate";

import { buildBackendUrl } from "./api/helpers";

// Register form components globally for extension templates
// Requires lowercase naming as vue-http-loader mangles PascalCase references in templates.
/* eslint-disable */
import TextInputFloatingLabel from "@/components/forms/TextInputFloatingLabel";
Vue.component("textinputfloatinglabel", TextInputFloatingLabel);
import CheckboxBorderedLabel from "@/components/forms/CheckboxBorderedLabel";
Vue.component("checkboxborderedlabel", CheckboxBorderedLabel);
import RadioBoxedLabel from "@/components/forms/RadioBoxedLabel";
Vue.component("radioboxedlabel", RadioBoxedLabel);
import RadioToggleBorderedLabel from "@/components/forms/RadioToggleBorderedLabel";
Vue.component("radiotoggleborderedlabel", RadioToggleBorderedLabel);
import RangeInputLabel from "@/components/forms/RangeInputLabel";
Vue.component("rangeinputlabel", RangeInputLabel);
/* eslint-enable */

import SvgIcon from "@/components/presentation/SvgIcon.vue";
Vue.component("SvgIcon", SvgIcon);

Vue.use(VueAxios, axios);
Vue.use(VueTranslate);
Vue.use(Vuedals);
Vue.use(AsyncComputed);

import locales from "@/locales/global";
Vue.locales(locales);

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("truncate", function (text, stop, clamp) {
  return text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");
});

Vue.filter("bcp47tag", function (language) {
  const regex = new RegExp(/([A-Z]{1}[a-z]{1})/g);
  return language.replace(regex, (match) => {
    return match.toUpperCase().padStart(match.length + 1, "-");
  });
});

Vue.directive("validate", validate);

const backendUrl = buildBackendUrl();

axios.defaults.baseURL = backendUrl;
axios.defaults.headers.post["Content-Type"] = "application/vnd.api+json";
axios.defaults.headers.put["Content-Type"] = "application/vnd.api+json";
axios.defaults.headers.patch["Content-Type"] = "application/vnd.api+json";

httpVueLoader.httpRequest = function (url) {
  return axios
    .get(url)
    .then(function (res) {
      return res.data;
    })
    .catch(function (err) {
      return Promise.reject(err.status);
    });
};

Vue.config.productionTip = false;

new Vue({
  backendUrl: backendUrl,
  store,
  render: (h) => h(App),
}).$mount("#app");
