<template>
  <div>
    <section>
      <fieldset>
        <RadioBoxedLabel
          :labelleft="{
            for: 'network_connectiontype_option_wlan',
            text: t('WiFi'),
          }"
          :labelright="{
            for: 'network_connectiontype_option_lan',
            text: t('Wired'),
          }"
          :hideinput="true"
          :selected="network_connectiontype.value === 'wlan' ? 'left' : 'right'"
        >
          <template #icon-left>
            <SvgIcon icon-name="wifi" class="custom-radio__icon" />
          </template>
          <template #input-left>
            <input
              type="radio"
              name="network_connectiontype"
              id="network_connectiontype_option_wlan"
              value="wlan"
              :checked="network_connectiontype.value === 'wlan'"
              @change="emitFormUpdate"
              required
            />
          </template>
          <template #icon-right>
            <SvgIcon icon-name="lan" class="custom-radio__icon" />
          </template>
          <template #input-right>
            <input
              type="radio"
              name="network_connectiontype"
              id="network_connectiontype_option_lan"
              value="lan"
              :checked="network_connectiontype.value === 'lan'"
              @change="emitFormUpdate"
              required
            />
          </template>
        </RadioBoxedLabel>
      </fieldset>
    </section>

    <section v-if="network_connectiontype.value.match(/wlan|^\s*$/) != null">
      <fieldset>
        <CheckboxBorderedLabel
          for="network_ssidinvisible"
          :text="t('Invisible network')"
        >
          <input
            type="checkbox"
            id="network_ssidinvisible"
            name="network_ssidinvisible"
            :value="network_ssidinvisible.value"
            true-value="yes"
            false-value="no"
            :checked="network_ssidinvisible.value === 'yes'"
            @change="emitFormUpdate"
          />
        </CheckboxBorderedLabel>

        <template v-if="network_ssidinvisible.value === 'no'">
          <div class="input-group">
            <label for="network_ssid" class="show-for-sr">{{
              t("Select your WiFi network")
            }}</label>
            <div
              v-if="loading"
              class="select--placeholder input-group-field grayscale"
            >
              <AnimatedLoader />
            </div>
            <select
              v-else
              class="input-group-field grayscale"
              id="network_ssid"
              ref="ssidSelect"
              name="network_ssid"
              required
              v-validate
              :value="network_ssid.value"
              @change="emitFormUpdate"
            >
              <option disabled selected value="">
                {{ t("Select your WiFi network") }}
              </option>
              <option
                v-for="(obj, idx) in availableNetworks"
                :key="idx"
                :value="obj.ssid"
              >
                {{ obj.ssid }} {{ obj.encryption ? "🔒" : null }} ({{
                  obj.signal
                }}%)
              </option>
            </select>
            <div class="input-group-button">
              <button
                :disabled="loading"
                type="button"
                class="button small"
                @click="getAvailableNetworks"
              >
                <SvgIcon icon-name="wifisync" />
                <AnimatedLoader v-if="loading" />
              </button>
            </div>
          </div>
        </template>
        <template v-else>
          <TextInputFloatingLabel
            for="network_ssid"
            :text="t('Name of your WiFi')"
          >
            <input
              type="text"
              id="network_ssid"
              name="network_ssid"
              required
              v-validate
              :value="network_ssid.value"
              placeholder=" "
              autocomplete="off"
            />
          </TextInputFloatingLabel>
        </template>
        <div
          class="input-group"
          v-if="
            selectedNetworkIsEncrypted || network_ssidinvisible.value === 'yes'
          "
        >
          <TextInputFloatingLabel
            for="network_password"
            :text="t('WiFi password')"
          >
            <input
              class="input-group-field"
              id="network_password"
              name="network_password"
              placeholder=" "
              @input="emitFormUpdate"
              :type="passwordVisible ? 'text' : 'password'"
              required
              v-validate
              :value="network_password.value"
              autocomplete="current-password"
            />
          </TextInputFloatingLabel>

          <div class="input-group-button">
            <button
              type="button"
              class="button small"
              @click="togglePasswordFieldVisibility"
            >
              <!-- SVG#use is rendered in shadowDOM, so a prop change doesn't show there -->
              <SvgIcon
                v-show="passwordVisible"
                icon-name="eye"
                class="icon--label"
              />
              <SvgIcon
                v-show="!passwordVisible"
                icon-name="eye-crossed"
                class="icon--label"
              />
            </button>
          </div>
        </div>
        <details class="text--smaller text--margin-bottom">
          <summary class="text--blue">
            {{ t("wifi_not_listed_summary") }}
          </summary>
          <br />
          <p>{{ t("setup_wifi_rescan_info") }}</p>
          <p class="">{{ t("setup_wifi_rescan_warning") }}</p>

          <button
            type="button"
            @click="triggerRescan"
            :disabled="rescanning"
            class="button button--icon secondary"
          >
            {{ t("setup_wifi_rescan_label") }}
            <SvgIcon icon-name="wifisync" />
          </button>
          <p>
            {{ t("setup_reboot_info") }}&nbsp;<a
              @click="$store.dispatch('triggerReboot')"
            >
              {{ t("setup_reboot_label") }}</a
            >.
          </p>
        </details>
      </fieldset>
    </section>
    <section v-else>
      <p class="centered">
        {{
          systemOffline
            ? t("connect your network cable now")
            : t("connected!") + " IP: " + primaryConnectionIP
        }}
      </p>
    </section>
  </div>
</template>
<script>
import TextInputFloatingLabel from "@/components/forms/TextInputFloatingLabel";
import CheckboxBorderedLabel from "@/components/forms/CheckboxBorderedLabel";
import RadioBoxedLabel from "@/components/forms/RadioBoxedLabel";

import { mapGetters } from "vuex";
import AnimatedLoader from "../AnimatedLoader.vue";

export default {
  name: "WLANSelectionBlock",
  components: {
    TextInputFloatingLabel,
    CheckboxBorderedLabel,
    AnimatedLoader,
    RadioBoxedLabel,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    network_connectiontype: {
      type: Object,
      required: true,
    },
    // eslint-disable-next-line vue/prop-name-casing
    network_ssidinvisible: {
      type: Object,
      required: true,
    },
    // eslint-disable-next-line vue/prop-name-casing
    network_ssid: {
      type: Object,
      required: true,
    },
    // eslint-disable-next-line vue/prop-name-casing
    network_password: {
      type: Object,
      required: true,
    },
    systemStatus: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      networkHidden: false,
      passwordVisible: false,
      availableNetworks: [],
      connectionType: "",
      loading: false,
      lastScan: null,
      rescanning: false,
      pollLastScanInterval: undefined,
    };
  },
  computed: {
    ...mapGetters(["systemOffline", "primaryConnectionIP"]),
    selectedNetworkIsEncrypted: function () {
      const selected = this.availableNetworks.filter(
        (wifi) => wifi.ssid === this.network_ssid.value
      );
      return selected[0] && selected[0].encryption;
    },
  },
  created: function () {
    this.getAvailableNetworks();
  },
  methods: {
    getAvailableNetworks: async function () {
      this.loading = true;
      try {
        const res = await this.axios.get("/system/control/network/list");
        this.availableNetworks = res.data.result;
        this.$nextTick(() => {
          this.$refs.ssidSelect.checkValidity();
        });
      } catch (error) {
        this.$store.dispatch("handleError", error);
      }
      this.loading = false;
    },
    /**
     * If we are currently in setup mode, the user is likely connected to the setup WiFi.
     * A rescan will temporarily close this connection, thus disconnecting the user.
     * In case their device uses a captive network assistant like iOS/macOS/Android, it will
     * close once the device disconnected from 'glancr setup'. For other cases, reload the setup
     * app as a best effort to restart the setup.
     */
    async triggerRescan() {
      this.rescanning = true;
      const response = await this.axios.get(
        "/system/control/network/request_scan"
      );
      this.lastScan = response.data?.result?.last_scan;
      this.pollLastScanInterval = window.setInterval(async () => {
        const response = await this.axios.get(
          "/system/control/network/last_scan"
        );
        const lastResponse = response.data?.result?.last_scan;
        if (this.lastScan !== lastResponse) {
          this.lastScan = lastResponse;
          this.rescanning = false;
          window.clearInterval(this.pollLastScanInterval);
          this.pollLastScanInterval = undefined;
          location.reload();
        }
      }, 5000);
    },
    emitFormUpdate: function (e) {
      // FIXME: This is a ugly hack, since somehow inbetween DOM, Vue and vuex, the event still (or again) holds the old value. This results in submitting the default "no" over and over.
      if (e.target.name === "network_ssidinvisible") {
        e.target.value = e.target.value === "no" ? "yes" : "no";
      }
      this.$emit("formupdate", e);
    },
    toggleSSIDField: function (e) {
      this.networkHidden = !this.networkHidden;
      this.emitFormUpdate(e);
    },

    togglePasswordFieldVisibility: function () {
      this.passwordVisible = !this.passwordVisible;
    },
  },
  locales: {
    enGb: {
      wifi_not_listed_summary: "Cannot find your WiFi?",
      setup_wifi_rescan_info:
        "If your WiFi does not show up even after reloading the available networks, you can trigger a full rescan below.",
      setup_wifi_rescan_warning:
        "To scan for your WiFi, mirr.OS must temporarily shut down the setup WiFi which you are connected to. Please reconnect once 'glancr setup' is available again.",
      setup_wifi_rescan_label: "Scan for WiFi and restart setup",
      setup_reboot_info:
        "If you continue to have troubles connecting to your WiFi, you can also",
      setup_reboot_label: "completely restart mirr.OS",
    },

    deDe: {
      "connect your network cable now": "schliesse jetzt dein Netzwerkkabel an",
      "connected!": "verbunden!",
      wifi_not_listed_summary: "Du kannst dein WLAN nicht finden?",
      setup_wifi_rescan_info:
        "Falls dein WLAN auch nach Neuladen der Liste verfügbarer Netzwerke nicht auftaucht, kannst du hier einen kompletten Re-scan starten.",
      setup_wifi_rescan_warning:
        "Um dein WLAN zu suchen, muss mirr.OS vorübergehend das Setup-WLAN deaktivieren, mit dem du verbunden bist. Bitte verbinde dich erneut, sobald 'glancr setup' wieder verfügbar ist.",
      setup_wifi_rescan_label: "WLAN suchen und Setup neu starten",
      setup_reboot_info:
        "Wenn du weiterhin Verbindungsprobleme mit deinem WLAN hast, kannst du auch",
      setup_reboot_label: "mirr.OS komplett neustarten",
    },
    frFr: {
      "connect your network cable now":
        "Connectez votre câble réseau maintenant",
      "connected!": "liée!",
      wifi_not_listed_summary: "Vous ne trouvez pas votre Wi-Fi?",
      setup_wifi_rescan_info:
        "Si votre WiFi ne s'affiche pas même après le rechargement des réseaux disponibles, vous pouvez déclencher une nouvelle analyse ci-dessous.",
      setup_wifi_rescan_warning:
        "Pour réanalyser votre WiFi, mirr.OS doit arrêter temporairement le WiFi de configuration auquel vous êtes connecté. Veuillez vous reconnecter une fois que la 'glancr setup' sera à nouveau disponible.",
      setup_wifi_rescan_label: "Redémarrer la configuration",
      setup_reboot_info:
        "Si vous continuez à avoir des difficultés à vous connecter à votre WiFi, vous pouvez également",
      setup_reboot_label: "completely restart mirr.OS",
    },
    esEs: {
      "connect your network cable now": "Conecte su cable de red ahora",
      "connected!": "¡conectada!",
      wifi_not_listed_summary: "¿No encuentras tu WiFi?",
      setup_wifi_rescan_info:
        "Si su WiFi no aparece incluso después de volver a cargar las redes disponibles, puede activar un nuevo escaneo a continuación.",
      setup_wifi_rescan_warning:
        "Para volver a escanear su WiFi, mirr.OS debe apagar temporalmente el WiFi de configuración al que está conectado. Por favor, vuelva a conectarse una vez que la 'glancr setup' esté disponible de nuevo.",
      setup_wifi_rescan_label: "Reiniciar la configuración",
      setup_reboot_info:
        "Si sigues teniendo problemas para conectarte a tu WiFi, también puedes",
      setup_reboot_label: "reiniciar completamente mirr.OS",
    },
    plPl: {
      "connect your network cable now": "Podłącz teraz kabel sieciowy",
      "connected!": "połączony!",
      setup_wifi_rescan_info:
        "Jeśli Wi-Fi nie pojawi się nawet po ponownym załadowaniu dostępnych sieci, możesz wywołać ponowne skanowanie poniżej.",
      wifi_not_listed_summary: "Nie możesz znaleźć swojego Wi-Fi?",
      setup_wifi_rescan_warning:
        "Aby ponownie zeskanować w poszukiwaniu Wi-Fi, mirr.OS musi tymczasowo wyłączyć konfigurację WiFi, z którą jesteś połączony. Połącz się ponownie, gdy 'glancr setup' będzie ponownie dostępna.",
      setup_wifi_rescan_label: "Uruchom ponownie konfigurację",
      setup_reboot_info:
        "Jeśli nadal masz problemy z połączeniem się z Wi-Fi, możesz również",
      setup_reboot_label: "całkowicie zrestartuj mirr.OS",
    },
    koKr: {
      "connect your network cable now": "지금 네트워크 케이블을 연결하십시오",
      "connected!": "연결되어있어!",
      wifi_not_listed_summary: "WiFi를 찾을 수 없습니까?",
      setup_wifi_rescan_info:
        "사용 가능한 네트워크를 다시 로드한 후에도 와이파이가 나타나지 않으면, 아래에서 재스캔을 트리거할 수 있습니다.",
      setup_wifi_rescan_warning:
        "WiFi를 다시 스캔하려면, mirr.OS는 연결된 설정 WiFi를 일시적으로 종료해야 합니다. 'glancr setup' 을 다시 사용할 수 있게 되면 다시 연결하세요.",
      setup_wifi_rescan_label: "설정을 다시 시작하세요",
      setup_reboot_info:
        "와이파이에 연결하는 데 계속 문제가 있다면, 당신은 또한 할 수 있습니다.",
      setup_reboot_label: "mirr.OS를 완전히 다시 시작하십시오.",
    },
  },
};
</script>
<style lang="scss" scoped>
.input-group {
  .button {
    width: 2.5rem;
    padding: 0;
  }

  svg {
    width: 1.25rem;
    height: auto;
  }
}

.centered {
  text-align: center;
}
</style>
