<template>
  <main>
    <div class="extension-filter">
      {{ t("show") }}:

      <button
        v-for="filter in filters"
        :value="filter"
        :key="filter"
        @click="() => (currentFilter = filter)"
        :class="['button--slim', filter === currentFilter ? 'active' : null]"
      >
        {{ t(filter) }}
      </button>
    </div>
    <section
      class="grid-x grid-padding-x grid-padding-y small-up-1 medium-up-2 large-up-3"
    >
      <div v-for="(source, id) in filteredSources" :key="id" class="cell">
        <ExtensionCard :extension="source" :language="language">
          <button
            slot="configuration"
            v-if="extensionIsInstalled(source.type, source.id)"
            type="button"
            class="button small"
            @click="manageSourceSettings(source)"
          >
            {{ t("Edit") }}
          </button>
        </ExtensionCard>
      </div>
    </section>
  </main>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { Bus as VuedalsBus } from "vuedals";

import ExtensionCard from "@/components/ExtensionCard.vue";
import SourceSettings from "@/components/SourceSettings.vue";
import ExtensionChecks from "@/mixins/ExtensionChecks";
import localisedAttribute from "@/mixins/localisedAttribute";

export default {
  name: "PageSources",
  components: {
    ExtensionCard,
  },
  mixins: [localisedAttribute, ExtensionChecks],
  data: () => {
    return {
      hover: false,
      currentFilter: "all",
      filters: ["all", "installed", "available"],
    };
  },
  computed: {
    ...mapGetters(["language", "activeSources", "inactiveSources"]),
    ...mapState(["sources"]),
    filteredSources: function () {
      switch (this.currentFilter) {
        case "installed": {
          return this.activeSources;
        }
        case "available": {
          return this.inactiveSources;
        }
        case "all":
        default:
          return this.sources;
      }
    },
  },
  beforeCreate: async function () {
    await this.$store.dispatch("fetchSources", ["sourceInstances"]);
  },
  methods: {
    toggleExpanded: function (sourceId) {
      this.expanded = {
        ...this.expanded,
        ...{ [sourceId]: !this.expanded[sourceId] },
      };
    },
    manageSourceSettings: function (source) {
      VuedalsBus.$emit("new", {
        title: this.localisedAttribute(source, "title", this.language),
        name: "settings-modal-" + source.id,
        component: SourceSettings,
        props: {
          source: source,
        },
        size: window.innerWidth <= 480 ? "xs" : "md",
      });
    },
  },
};
</script>
