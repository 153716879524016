<template>
  <div class="webapp-hint">
    <div class="webapp-hint__indicator" />
    <p class="webapp-hint__content">
      <slot />{{ t("Add mirr.OS to your home screen.") }}
      {{ t("Tap") }}&nbsp;&nbsp;&#8942;&nbsp;&nbsp;{{ t("and then") }}
      <b>{{ t("Add to Homescreen") }}</b>
    </p>
  </div>
</template>
<script>
export default {
  name: "WebappHintChromeAndroid",
};
</script>
<style lang="scss" scoped>
.webapp-hint {
  max-width: 90vw;
}

.webapp-hint__content {
  padding: 1rem;
  border-radius: $global-radius;
  background-color: $black;
  margin: 0;
  color: $white;
  position: relative;

  > svg {
    vertical-align: middle;
  }
}

.webapp-hint__close {
  position: absolute;
  top: 0;
  right: 0.5rem;
  color: $white;
  font-size: 2rem;
}

.webapp-hint__indicator {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  position: relative;

  left: 81vw;
  border-bottom: 16px solid $black;
}
</style>
