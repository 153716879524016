<template>
  <main class="container-padding">
    <h5 class="instruction__title">
      {{ t("Before you start") }}
    </h5>
    <p class="instruction__text">
      {{
        t(
          "Before you contact us, please try if one of the options below resolves your problem."
        )
      }}
    </p>
    <div class="button-row">
      <button class="button" type="button" @click="refreshDisplayApp">
        {{ t("reload screen") }}
      </button>
      <button class="button" type="button" @click="confirmReboot">
        {{ t("restart mirr.OS") }}
      </button>
      <button class="button" type="button" @click="triggerReset">
        {{ t("reset mirr.OS") }}
      </button>
      <button class="button" type="button" @click="triggerShutdown">
        {{ t("shut down") }}
      </button>
      <a href="/display#preview" target="_blank" class="button">
        {{ t("Preview") }}
      </a>
    </div>

    <p class="text-center margin-top">
      <em
        >mirr.OS {{ t("version") }}: Snap {{ systemStatus.snap_version }} / API
        {{ systemStatus.api_version }} / Settings App {{ appVersion }}</em
      >
    </p>
    <hr />

    <form @submit.prevent="submitDebugReport">
      <fieldset>
        <legend class="h5">
          <em>{{ t("Privacy notice") }}</em>
        </legend>
        <p>
          {{
            t(
              "The information provided in this form will be processed to determine technical difficulties with your installation of mirr.OS. For further details on the data we collect and how it is processed, refer to our privacy statement below."
            )
          }}
        </p>
        <PrivacyStatementText />
        <br />
        <CheckboxBorderedLabel for="privacy-consent" :text="t('I agree')">
          <input
            type="checkbox"
            required
            aria-required="true"
            name="privacy-consent"
            id="privacy-consent"
            v-model="form.privacyConsent"
          />
        </CheckboxBorderedLabel>
      </fieldset>

      <fieldset>
        <legend class="h5">1. {{ t("Describe your problem") }}</legend>
        <p>
          {{
            t(
              "If you experience problems with mirr.OS or with extensions you can use this form to send us a system report. To respond faster to your request, this also sends a copy of your logfiles. You can review the logfile content below before you send the report."
            )
          }}
        </p>

        <TextInputFloatingLabel for="title" :text="t('Title')">
          <input
            type="text"
            id="title"
            name="title"
            required
            aria-required="true"
            :placeholder="t('Please keep it concise')"
            v-model="form.title"
          />
        </TextInputFloatingLabel>

        <TextInputFloatingLabel
          for="description"
          :text="t('Describe the issues you encountered.')"
        >
          <textarea
            rows="5"
            id="description"
            name="description"
            required
            aria-required="true"
            :placeholder="t('Detailed')"
            v-model="form.description"
          />
        </TextInputFloatingLabel>

        <!-- <select name="os" id="" v-model="form.os">
          <option disabled selected value="">Choose your OS</option>
          <option v-for="os in formOptions.os" :value="os" :key="os">{{
            os
          }}</option>
        </select>
        <select name="os" id="" :disabled="form.os === ''">
          <option disabled selected value="">Choose your browser</option>
          <option
            v-for="browser in browsersForOs"
            :value="browser"
            :key="browser"
            >{{ browser }}</option
          >
        </select> -->

        <p>{{ t("Where should we send our reply?") }}</p>
        <TextInputFloatingLabel for="email" :text="t('your email address')">
          <input
            type="email"
            id="email"
            name="email"
            placeholder="mail@example.com"
            :value="settings.personal_email.attributes.value"
          />
        </TextInputFloatingLabel>
      </fieldset>

      <p class="h5">2. {{ t("check your logfiles and system report") }}</p>

      <p>
        {{
          t(
            "The logfiles contain technical information about your installation of mirr.OS. It should not contain sensitive information like passwords or account details."
          )
        }}
      </p>

      <details>
        <summary @click="fetchLogfiles" class="h6 text--blue">
          {{ t("Logfiles") }}
        </summary>
        <div v-if="logfiles.fetching">
          {{ t("") }}
        </div>
        <h5>Production Log</h5>
        <pre v-if="logfiles.rails.length > 0" class="small">
          {{ logfiles.rails }}
        </pre>
        <p v-else>
          {{ t("no log entries yet") }}
        </p>

        <h5>Scheduler log</h5>
        <pre v-if="logfiles.scheduler.length > 0" class="small">
          {{ logfiles.scheduler }}
        </pre>
        <p v-else>
          {{ t("no log entries yet") }}
        </p>
      </details>

      <p>
        {{
          t(
            "The system report shows us how your mirr.OS is configured and which extensions you use. For privacy reasons, we anonymize your data as far as possible. This means: We do not receive passwords, tokens or other registration data."
          )
        }}
      </p>

      <details>
        <summary @click="fetchSystemReport" class="h6 text--blue">
          {{ t("System report") }}
        </summary>
        <pre>
          {{ JSON.stringify(systemReport, null, 2) }}
        </pre>
      </details>

      <button
        class="button"
        type="submit"
        :disabled="submitting || formSent != null"
      >
        <!-- TODO: Refactor form logic together with settings apply form -->
        <span v-if="formSent != null">
          {{
            formSent === true
              ? t("Thank you for your report") + " ✓"
              : t("error") + " ✗"
          }}
        </span>
        <span v-else>
          {{ submitting ? t("sending …") : t("Send debug report") }}
        </span>
      </button>
    </form>
  </main>
</template>

<script>
import PrivacyStatementText from "@/components/presentation/PrivacyStatementText";

import TextInputFloatingLabel from "@/components/forms/TextInputFloatingLabel";
import CheckboxBorderedLabel from "@/components/forms/CheckboxBorderedLabel";

import { mapState } from "vuex";
import Axios from "axios";

export default {
  name: "PageHelp",
  components: {
    PrivacyStatementText,
    TextInputFloatingLabel,
    CheckboxBorderedLabel,
  },
  data: function () {
    return {
      appVersion: process.env.VUE_APP_VERSION,
      form: {
        privacyConsent: false,
        title: "",
        description: "",
        os: "",
        browser: "",
      },
      logfiles: {
        fetched: false,
        rails: "",
        scheduler: "",
      },
      systemReport: "",
      submitting: false,
      formSent: null,
    };
  },
  computed: {
    ...mapState(["systemStatus", "settings"]),
  },
  methods: {
    confirmReboot: async function () {
      if (
        window.confirm(
          this.$translate.text("Do you really want to reboot mirr.OS?")
        )
      ) {
        await this.$store.dispatch("triggerReboot");
      }
    },
    triggerShutdown: async function () {
      if (
        window.confirm(
          this.$translate.text(
            "Do you really want to shut down mirr.OS completely? Please note: Starting your Raspberry Pi usually involves replugging the power cord!"
          )
        )
      ) {
        try {
          let res = await Axios.get("/system/shut_down");
          this.$store.dispatch("handleNotification", {
            status: res.status,
            message: this.$translate.text("mirr.OS will shut down now"),
          });
          return res.status;
        } catch (error) {
          this.$store.dispatch("handleError", error);
        }
      }
    },
    refreshDisplayApp: async function () {
      try {
        let res = await Axios.get("/system/reload_browser");
        this.$store.dispatch("handleNotification", {
          status: res.status,
          message: this.$translate.text("display app was reloaded"),
        });
        return res.status;
      } catch (error) {
        this.$store.dispatch("handleError", error);
      }
    },
    triggerReset: async function () {
      // TODO: Move to API module
      if (
        window.confirm(
          this.$translate.text(
            "Do you really want to reset all mirr.OS settings? This will also remove all your configuration for extensions and will uninstall all extensions that were not installed by default."
          )
        )
      ) {
        try {
          this.$store.commit("TOGGLE_BLOCKING_STATUS");
          // Allow the backend to perform tasks for 3 minutes, it's running heavy Ruby stuff on an RPi.
          await Axios.get("/system/reset", { timeout: 180000 });

          this.resetSucessFeedback();
        } catch (error) {
          if (error.code === "ECONNABORTED") {
            // If the backend takes more than 3 minutes, it's still probable that the reset is running fine.
            this.resetSucessFeedback();
          } else {
            this.$store.dispatch("handleError", error);
          }
        } finally {
          this.$store.commit("TOGGLE_BLOCKING_STATUS");
        }
      }
    },
    resetSucessFeedback: function () {
      window.alert(
        this.$translate.text(
          "Reset was successful. Your mirror will now reboot. Check your emails for further instructions."
        )
      );
      const name = this.settings.personal_name.attributes.value;
      const language = this.$options.filters.bcp47tag(
        this.settings.system_language.attributes.value
      );
      document.location = `https://api.glancr.de/reset/?name=${name}&language=${language}`;
    },
    fetchLogfiles: async function () {
      // TODO: Move to API module
      if (this.logfiles.fetched === true) {
        return;
      }
      try {
        let res = await Axios.get("/system/logs/production");
        this.logfiles.rails = res.data;

        res = await Axios.get("/system/logs/scheduler");
        this.logfiles.scheduler = res.data;

        this.logfiles.fetched = true;
      } catch (error) {
        this.$store.dispatch("handleError", error);
      }
    },
    fetchSystemReport: async function () {
      // TODO: Move to API module
      if (this.systemReport.length > 0) return;
      try {
        let res = await Axios.get("/system/report");
        this.systemReport = res.data;
      } catch (error) {
        this.$store.dispatch("handleError", error);
      }
    },
    submitDebugReport: async function (e) {
      const data = new FormData(e.target);
      this.submitting = true;
      try {
        const res = await Axios.post("/system/report/send", data);
        this.formSent = true;
        for (let key in this.form) {
          switch (typeof this.form[key]) {
            case "string":
              this.form[key] = "";
              break;
            case "boolean":
              this.form[key] = false;
              break;
            default:
              this.form[key] = null;
              break;
          }
        }
        this.$store.dispatch("handleNotification", {
          status: res.status,
          message: this.$translate.text("Thank you for your report"),
        });
      } catch (error) {
        this.formSent = false;
      } finally {
        this.submitting = false;
        setTimeout(() => {
          this.formSent = null;
        }, 5000);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
pre {
  max-width: inherit;
  overflow-y: scroll;
}

.margin-top {
  margin-top: 1rem;
}
</style>
