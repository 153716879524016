export default {
  methods: {
    /**
     * Transforms an input string with the ROT13 substitution cipher.
     * Props to https://codereview.stackexchange.com/a/132140
     *
     * @param {string} str The input to cipher.
     * @returns {string|undefined} The ROT13-ciphered input, or undefined for invalid input.
     */
    rot13: function (str) {
      const input = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      const output = "NOPQRSTUVWXYZABCDEFGHIJKLMnopqrstuvwxyzabcdefghijklm";
      const index = (x) => input.indexOf(x);
      const translate = (x) => (index(x) > -1 ? output[index(x)] : x);
      return str?.split("").map(translate).join("");
    },
    /**
     * Updates the store state that controls if the user is granted access.
     *
     * @param {boolean} status Whether the input matches the stored (ciphered) password.
     */
    updatePasswordMatchStatus: function (status) {
      this.$store.commit("CHANGE_PASSWORD_MATCH_STATUS", status);
    },
  },
};
