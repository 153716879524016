<template>
  <div class="card">
    <header class="card-section extension__info">
      <img
        class="extension__icon"
        :src="extensionIconPath"
        :alt="extension.attributes.name"
      />
      <div>
        <h5 class="extension__name">
          {{ localisedAttribute(extension, "title", language) }}
        </h5>

        <p class="extension__version">
          <span>v / {{ extension.attributes.version }}</span>
        </p>
        <p class="extension__description">
          {{
            localisedAttribute(extension, "description", language)
              | truncate(110)
          }}
        </p>
      </div>
    </header>
    <footer class="extension__actions">
      <!-- TODO: Refactor as a sub-component -->
      <template v-if="extension.attributes.active">
        <button
          type="button"
          class="button alert small"
          @click="uninstallExtension(extension.id)"
          :disabled="uninstalling"
        >
          <AnimatedLoader v-if="uninstalling" />
          <template v-else>
            {{ t("Uninstall") }}
          </template>
        </button>
        <slot name="configuration" />
      </template>
      <template v-else>
        <button
          type="button"
          class="button small"
          @click="installExtension()"
          :disabled="installing"
        >
          <AnimatedLoader v-if="installing" />
          <template v-else>
            {{ t("Install") }}
          </template>
        </button>
      </template>
    </footer>
  </div>
</template>

<script>
import AnimatedLoader from "@/components/AnimatedLoader.vue";
import ExtensionChecks from "@/mixins/ExtensionChecks";
import localisedAttribute from "@/mixins/localisedAttribute";
import { mapGetters, mapState } from "vuex";
import { api } from "@/api/operations";

export default {
  name: "ExtensionCard",
  components: {
    AnimatedLoader,
  },
  mixins: [ExtensionChecks, localisedAttribute],
  props: {
    extension: {
      type: Object,
      required: true,
    },
    language: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      installing: false,
      updating: false,
      uninstalling: false,
    };
  },
  computed: {
    ...mapGetters(["relatedEntity"]),
    ...mapState(["systemStatus"]),
    /**
     * @returns {String} SVG icon URL
     */
    extensionIconPath: function () {
      const name = this.extension.attributes.name;

      return this.extensionIsInstalled(this.extension.type, name)
        ? api.getExtensionAssetPath({
            extType: this.extension.type,
            extension: name,
            assetType: "icons",
            filename: `${name}.svg?version=${this.extension.attributes.version}`,
          })
        : this.extension.attributes.icon;
    },
  },
  methods: {
    getRelatedGroup: function () {
      if (
        !Object.prototype.hasOwnProperty.call(this.extension, "relationships")
      ) {
        return [];
      }

      let extGroups = this.extension.relationships.groups.data;

      switch (this.extension.type) {
        case "widgets":
          return this.$store.state.groups[extGroups.id];
        case "sources":
          return extGroups.map((group) => {
            return this.$store.state.groups[group.id];
          });
      }
    },

    installExtension: async function () {
      this.installing = true;
      try {
        await this.$store.dispatch(
          "enableExtension",
          Object.assign({}, this.extension)
        );
      } catch (error) {
        // TODO: Errors are handled in installExtension action, do we need something here?
      } finally {
        this.installing = false;
      }
    },
    updateExtension: function (extensionId) {
      this.updating = true;
      this.$store.commit("TOGGLE_BLOCKING_STATUS");

      let slice = `${this.extension.type}Available`;
      let payload = this.$store.state[slice][extensionId];
      payload.id = extensionId;
      this.$store.dispatch("updateExtension", payload).then(() => {
        this.updating = false;
        this.$store.commit("TOGGLE_BLOCKING_STATUS");
      });
    },
    uninstallExtension: function () {
      if (
        window.confirm(
          `${this.$translate.text(
            "Do you really want to uninstall this extension:"
          )} ${this.localisedAttribute(this.extension, "title", this.language)}`
        )
      ) {
        this.uninstalling = true;
        this.$store
          .dispatch("disableExtension", Object.assign({}, this.extension))
          .then(() => {
            this.uninstalling = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
.extension {
  width: 40%;
  padding: 1rem;

  img {
    width: 100%;
    height: auto;
  }
}

.cell > .card {
  overflow: visible;
  margin-bottom: 0;
}

.icon--label {
  height: 1rem;
}

.extension__info {
  display: flex;
  position: relative;
  align-items: flex-start;
}

.extension__icon {
  max-width: 15%;
  padding: 0.125rem;
  margin-right: 1rem;
}

.extension__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-weight: 500;
}

.extension__version {
  color: $medium-gray;
  margin-bottom: 0;
  position: absolute;
  top: 0.8125rem;
  right: 1rem;
}

.extension__description {
  overflow: hidden;
  margin: 0;
  height: 3.375rem;
  line-height: 1.125rem;
}

.extension__actions {
  display: flex;
  padding: 0 0.5rem 1rem 0.5rem;
  justify-content: flex-end;
  button {
    margin: 0 0.5rem;
    min-width: 4.375rem;
  }
  span {
    width: 15%;
  }
}
</style>
