<template>
  <div class="error">
    <div>
      <b>{{ t("Error") }} {{ status }}: {{ title }}</b
      >&nbsp;<span v-if="detail != title">{{ detail }}</span>
      <em v-if="source">({{ t("Source") }}: {{ source }}</em>
    </div>
    <button class="error__closebutton" @click="unmount()">X</button>
  </div>
</template>

<script>
export default {
  name: "ErrorMessage",
  props: {
    status: { type: String, default: "400" },
    title: { type: String, default: "" },
    detail: { type: String, default: "" },
    source: { type: String, default: undefined },
  },
  methods: {
    unmount: function () {
      this.$store.commit("removeError", this.errorId);
    },
  },
};
</script>

<style lang="scss">
.error {
  background-color: rgba(235, 0, 0, 1);
  color: $white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  z-index: 99;
  height: 3.65rem;
  padding: 1rem;
}
.error__closebutton {
  color: $white;
}
</style>
