<template>
  <select id="system_timezone" name="system_timezone" @change="emitFormUpdate">
    <option disabled selected value="">
      <em>{{ t("Select your time zone") }}</em>
    </option>
    <option
      v-for="(timezone, key) in system_timezone.options"
      :key="key"
      :value="timezone.id"
      :selected="timezone.id === system_timezone.value"
    >
      {{ timezone.name }}
    </option>
  </select>
</template>
<script>
import emitFormUpdate from "@/mixins/EmitFormUpdate";

export default {
  name: "TimezoneSelectionBlock",
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    system_timezone: {
      type: Object,
      required: true,
    },
  },
  mixins: [emitFormUpdate],
};
</script>
