export default {
  methods: {
    extensionIsInstalled: function (type, id) {
      return this.$store.state[type][id] != undefined;
    },
    extensionHasUpdate: function (type, id) {
      return this.$store.getters.extensionUpdatesAvailable(type).includes(id);
    },
  },
};
