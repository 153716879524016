<template>
  <div class="board-list" v-on-clickaway="closeIfOpen">
    <div class="board-list__selected" @click="toggleOpen">
      {{ selectedTitle }}
      <SvgIcon
        icon-name="triangle"
        class="board-list__icon"
        :class="open ? 'expanded' : 'closed'"
      />
    </div>
    <button
      type="button"
      class="button board-list__action"
      :disabled="editingBoardId === activeBoardId"
      @click="activateBoard"
    >
      <SvgIcon icon-name="activate" width="24" height="32" />
    </button>

    <transition name="unroll-top">
      <ul class="board-list__list" v-show="open">
        <BoardSelectorListItem
          v-for="board in boards"
          :key="board.id"
          :board="board"
          :active="board.id === activeBoardId"
          :loaded="board.id === editingBoardId"
          @load-board="loadBoardForEditing"
          @show-settings="showBoardSettings"
        />
        <li class="board-list__list-item">
          <template v-if="boardFormActive">
            <form @submit.prevent="newBoard" class="full-width" novalidate>
              <TextInputFloatingLabel
                for="board-title"
                :text="t('Board title')"
              >
                <input
                  type="text"
                  name="title"
                  id="board-title"
                  ref="boardTitle"
                  :placeholder="t('untitled board')"
                />
              </TextInputFloatingLabel>
              <div class="flex-row full-width">
                <button
                  class="button alert button--symbol expanded"
                  type="button"
                  @click="toggleNewBoardTitle"
                >
                  {{ t("cancel") }}
                </button>
                <button type="submit" class="button button--symbol expanded">
                  {{ t("save") }}
                </button>
              </div>
            </form>
          </template>
          <template v-else>
            <span @click="toggleNewBoardTitle" class="flex-row full-width">
              <span>{{ t("new board") }}</span>
              <SvgIcon icon-name="add" icon-color="green" />
            </span>
          </template>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import { Bus as VuedalsBus } from "vuedals";
import { mixin as clickaway } from "vue-clickaway";

import BoardSettings from "./BoardSettings";
import BoardSelectorListItem from "./BoardSelectorListItem";
import TextInputFloatingLabel from "@/components/forms/TextInputFloatingLabel";

import { SettingResource, BoardResource } from "@/api/models";

import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "BoardSelector",
  components: {
    BoardSelectorListItem,
    TextInputFloatingLabel,
  },
  mixins: [clickaway],
  props: {
    editingBoardId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      open: false,
      boardFormActive: false,
    };
  },
  computed: {
    ...mapState(["boards"]),
    ...mapGetters(["activeBoardId"]),
    selectedTitle: function () {
      return this.boards[this.editingBoardId]?.attributes.title;
    },
  },
  beforeMount: async function () {
    await this.$store.dispatch("fetchBoards", {
      includes: ["widgetInstances"],
    });
  },
  methods: {
    ...mapActions(["createBoard", "loadBoard"]),
    toggleOpen: function () {
      this.open = !this.open;
    },
    closeIfOpen: function () {
      if (this.open) {
        this.toggleOpen();
      }
    },
    toggleNewBoardTitle: function () {
      this.boardFormActive = !this.boardFormActive;
      if (this.boardFormActive) {
        this.$nextTick(() => {
          this.$refs.boardTitle.focus();
        });
      }
    },
    /**
     * @param {string} title
     */
    newBoard: function (e) {
      const fd = new FormData(e.target);
      let resource = new BoardResource();
      for (let [key, val] of fd.entries()) {
        resource.setAttribute(key, val);
      }
      this.createBoard(resource);
      this.boardFormActive = false;
    },
    loadBoardForEditing: async function (boardId) {
      await this.loadBoard(boardId);
      this.open = false;
    },
    activateBoard: function () {
      let res = new SettingResource("system_activeboard");
      res.attributes.value = this.editingBoardId;
      this.$store.dispatch("updateSetting", {
        resource: res,
        // FIXME: Change to textWithParams once https://github.com/javisperez/vuetranslate/pull/19 has landed
        notification: `${this.selectedTitle} ${this.$translate.text(
          "is now active"
        )}`,
      });
    },
    showBoardSettings: function (boardId) {
      VuedalsBus.$emit("new", {
        title: this.boards[boardId].attributes.title,
        name: `settings-modal-board-${boardId}`,
        component: BoardSettings,
        props: {
          boardId: boardId,
        },
        size: window.innerWidth <= 480 ? "xs" : "md", // FIXME: Determine from global settings once established.
      });
    },
  },
};
</script>

<style lang="scss" scoped>
//FIXME: Clean up, lots is copied from form styling.
.board-list {
  background-color: $white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  z-index: 9;
  flex-grow: 3;
}

.board-list__selected {
  height: 3.5rem;
  padding: 0.5rem;
  border: 1px solid $light-gray;
  border-radius: 3px 0 0 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  width: 100%;
}

.board-list__icon {
  height: 0.75rem;
  &.expanded {
    transition: transform 0.5s;
  }
  &.closed {
    transform: rotate(180deg);
    transform-origin: center;
    transition: transform 0.5s;
  }
}

.board-list__list {
  list-style-type: none;
  background-color: #fff;
  border: 1px solid $light-gray;
  box-shadow: $global-box-shadow;
  box-sizing: border-box;
  position: absolute;
  left: $global-padding * 0.5;
  top: 3.75rem;
  width: calc(100% - #{$global-padding});

  margin-left: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  z-index: 99;
}

.board-list__action {
  width: 3rem;
  height: 3.5rem;
  padding: 0.75rem;
  border-radius: 0 3px 3px 0;
  margin-bottom: 0;
}
.flex-row {
  display: flex;
  justify-content: space-between;

  > * {
    margin-right: 0.5rem;
    &:last-child {
      margin-right: unset;
    }
  }
}

.full-width {
  width: 100%;
}
</style>
