<template>
  <div class="sourceinstances__list">
    <hr class="divider--expanded" />

    <div v-for="account in sourceInstances" :key="account.id">
      <p class="sourceinstance__row" @click="toggleCurrentForm(account.id)">
        {{ account.attributes.title || t("no title") }}
        <span
          :class="[
            'sourceinstance__toggle',
            currentForm === account.id ? 'expanded' : 'closed',
          ]"
        >
          <SvgIcon
            icon-name="triangle"
            color="var(--theme-color)"
            width="10"
            height="10"
          />
        </span>
      </p>
      <transition name="fade" mode="out-in" appear>
        <SourceInstanceSettings
          v-if="currentForm === account.id"
          :base-source="baseSource"
          :instance-id="account.id"
        />
      </transition>
      <hr class="divider--expanded" />
    </div>

    <p @click.prevent="toggleCurrentForm('new')">
      <span>{{ t("Add new account") }}</span>
      <button
        v-if="currentForm === 'new'"
        class="accountform__button accountform__button--inset"
      >
        ✕
      </button>
      <SvgIcon
        v-else
        icon-name="add"
        class="accountform__button"
        color="var(--theme-color)"
        width="14"
        height="14"
      />
    </p>
    <transition name="fade" mode="out-in" appear>
      <!-- Using v-if is more expensive, but prevents duplicate form IDs -->
      <SourceInstanceSettings
        v-if="currentForm === 'new' || this.sourceInstances.length === 0"
        :base-source="baseSource"
      />
    </transition>
  </div>
</template>

<script>
import SourceInstanceSettings from "@/components/SourceInstanceSettings.vue";

export default {
  name: "SourceInstanceList",
  components: {
    SourceInstanceSettings,
  },
  props: {
    baseSource: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      error: "",
      loaded: false,
      currentForm: null,
      newAccountVisible: false,
    };
  },
  computed: {
    sourceInstances: function () {
      return this.$store.getters
        .relatedEntity("sources", this.baseSource, "sourceInstances")
        .sort(function (a, b) {
          // SourceInstance IDs are numeric strings, use simplified number comparison.
          return Number.parseInt(a.id) - Number.parseInt(b.id);
        });
    },
  },
  watch: {
    sourceInstances: function (newVal) {
      this.newAccountVisible = newVal.length === 0;
    },
  },
  methods: {
    toggleCurrentForm: function (formId) {
      this.currentForm = this.currentForm === formId ? null : formId;
    },
  },
};
</script>
<style lang="scss">
.sourceinstances__list {
  padding: 0;
}

.sourceinstance__row {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.sourceinstance__toggle {
  .svg--inline {
    height: 0.5rem;
    margin-right: 0.125rem;
  }
  &.expanded svg {
    transition: transform 0.5s;
  }
  &.closed svg {
    transform: rotate(180deg);
    transform-origin: center;
    transition: transform 0.5s;
  }
}

.sourceinstance__add {
  background-color: var(--theme-color);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  position: relative;
  top: 1rem;
}

.accountform__button {
  float: right;
}

.accountform__button--inset {
  padding-right: 0.25rem;
}
</style>
