import german from "@/locales/de_DE.json";
import french from "@/locales/fr_FR.json";
import spanish from "@/locales/es_ES.json";
import polish from "@/locales/pl_PL.json";
import korean from "@/locales/ko_KR.json";

export default {
  deDe: german,
  frFr: french,
  esEs: spanish,
  plPl: polish,
  koKr: korean,
};
