<template functional>
  <label :for="props.for" class="custom-checkbox">
    <!-- @slot Use this slot to place the checkbox input element -->
    {{ props.text }}<slot />
    <span class="custom-checkbox__checkmark" />
  </label>
</template>
<script>
/**
 * Wrapper component to style checkboxes in a consistent, boxed layout.
 */
export default {
  name: "CheckboxBorderedLabel",
  props: {
    /**
     * Associates the label element with the input ID.
     */
    for: {
      type: String,
      required: true,
    },
    /**
     * Text to use for the input label.
     */
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss">
// Adapted from https://www.w3schools.com/howto/howto_css_custom_checkbox.asp
.custom-checkbox {
  border: $input-border;
  border-radius: $global-radius;
  color: $dark-gray;
  font-size: initial;

  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 3.5rem;

  margin-bottom: $global-margin;
  padding: $global-padding/2;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  /* On mouse-over, add a grey background color */
  &:hover input ~ .custom-checkbox__checkmark {
    border-color: var(--theme-color);
  }

  /* When the checkbox is checked, add a colored background */
  input:checked ~ .custom-checkbox__checkmark {
    background-color: var(--theme-color);
    border: none;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .custom-checkbox__checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .custom-checkbox__checkmark:after {
    display: block;
  }
}

/* Style the checkmark/indicator */
.custom-checkbox__checkmark {
  height: 1rem;
  width: 1rem;
  background-color: $white;
  border: $input-border;
  border-radius: $global-radius;
  position: relative;

  &:after {
    width: 0.5rem;
    height: 0.75rem;
    position: relative;
    left: 0.25rem;
    border: solid white;
    border-radius: $global-radius/4;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
</style>
