var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"widgetlist__wrapper",class:{ scrolled: _vm.pageScrolled.bottom }},[_c('button',{class:[
      'widgetlist__scrollbutton',
      'text--gray',
      _vm.overflowLeft ? 'shadow' : null,
    ],attrs:{"type":"button"},on:{"click":function($event){return _vm.scroll('right')}}},[_c('SvgIcon',{attrs:{"icon-name":"arrow-back"}})],1),_c('div',{staticClass:"widgetlist__inner",attrs:{"id":"widgetlist__inner"},on:{"scroll":_vm.updateScrollState}},_vm._l((_vm.activeWidgets),function(widget,id){return _c('WidgetListItem',{key:id,attrs:{"widget":widget,"language":_vm.language,"classes":_vm.gridLocked ? 'locked' : null}})}),1),_c('button',{class:[
      'widgetlist__scrollbutton',
      'text--gray',
      _vm.overflowRight ? 'shadow' : null,
    ],attrs:{"type":"button"},on:{"click":function($event){return _vm.scroll('left')}}},[_c('SvgIcon',{staticStyle:{"transform":"rotate(180deg)"},attrs:{"icon-name":"arrow-back"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }