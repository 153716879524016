<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    :aria-labelledby="iconName"
    role="presentation"
    :fill="iconColor"
  >
    <use :xlink:href="`icon-sprite.svg#icon-${iconName}`" />
  </svg>
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    iconName: {
      type: String,
      required: true,
    },
    width: {
      type: [Number, String],
      required: false,
      default: 18,
    },
    height: {
      type: [Number, String],
      required: false,
      default: 18,
    },
    iconColor: {
      type: String,
      required: false,
      default: "currentColor",
    },
  },
};
</script>
