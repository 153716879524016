// Contains only keys that are updatable through the API.
const merge = require("deepmerge");

class BaseResource {
  constructor(type, { id = undefined }) {
    /** @type {String} */
    this.type = type;
    /** @type {String | undefined} */
    this.id = id;
    this.attributes = {};
    this.relationships = {};
  }
  /**
   * Adds an attribute
   * @param {string} name
   * @param {*} data
   */
  setAttribute(name, data) {
    this.attributes[name] = data;
  }
  /**
   * Adds a singular relationship to another model.
   * @param {string} name
   * @param {Object} data
   */
  setRelationship(name, data) {
    this.relationships[name] = { data: data };
  }
}

export class SettingResource {
  constructor(id) {
    /** @type {String} */
    this.type = "settings";
    /** @type {String} */
    this.id = id;
    /** @type {Object} */
    this.attributes = {
      /** @type {String} */
      value: "",
    };
  }
}

export class WidgetResource {
  constructor(id) {
    /** @type {String} */
    this.type = "widgets";
    /** @type {String} */
    this.id = id;
    /** @type {Object} */
    this.attributes = {
      /** @type {String} */
      name: id,
    };
  }
}

export class WidgetInstanceResource extends BaseResource {
  constructor(options = {}) {
    super("widget-instances", options);
    this.attributes = {};

    if (options.id != undefined) {
      this.id = options.id;
    }
  }
  setWidgetRelationship(widgetId) {
    this.setRelationship("widget", {
      id: widgetId,
      type: "widgets",
    });
  }

  setBoardRelationship(boardId) {
    this.setRelationship("board", {
      id: boardId,
      type: "boards",
    });
  }

  /**
   * Sets the position and dimensions of this widget instance.
   * @param {object} positionObj
   * @param {number} positionObj.width – the current width in grid columns
   * @param {number} positionObj.height – the current height in grid rows
   * @param {number} positionObj.x – horizontal grid position of the widget's left upper corner
   * @param {number} positionObj.y – vertical grid position of the widget's left upper corner
   */
  setPosition(positionObj) {
    this.attributes.position = positionObj;
  }

  /**
   * Sets a widget attribute. If the key contains dots, creates a nested attribute object.
   * @param {string} key The attribute key
   * @param {string|number|boolean} val The value for the given key within attributes
   */
  setAttribute(key, val) {
    let newValues = {};
    key.split(".").reduce((acc, key, idx, srcArr) => {
      if (srcArr.length === 1 || idx === srcArr.length - 1) {
        return (acc[key] = val);
      } else {
        return (acc[key] = { ...acc[key] });
      }
    }, newValues);
    this.attributes = merge(this.attributes, newValues);
  }

  addConfig(key, val) {
    if (this.attributes.configuration[key]) {
      if (!(this.attributes.configuration[key] instanceof Array)) {
        this.attributes.configuration[key] = new Array(
          this.attributes.configuration[key]
        );
      }
      this.attributes.configuration[key].push(val);
    } else {
      this.attributes.configuration[key] = val;
    }
  }
  getConfig(key) {
    return this.attributes.configuration[key];
  }
  configHasKey(key) {
    return Object.prototype.hasOwnProperty.call(
      this.attributes.configuration,
      key
    );
  }
}

export class SourceInstanceResource {
  constructor(options = {}) {
    this.type = "source-instances";
    this.attributes = {
      configuration: {},
    };
    if (options.id != undefined) {
      this.id = options.id;
    }
    if (options.source != undefined) {
      this.relationships = {
        source: { data: { id: options.source, type: "sources" } },
      };
    }
  }
  addConfig(key, val) {
    if (this.attributes.configuration[key]) {
      if (!(this.attributes.configuration[key] instanceof Array)) {
        this.attributes.configuration[key] = new Array(
          this.attributes.configuration[key]
        );
      }
      this.attributes.configuration[key].push(val);
    } else {
      this.attributes.configuration[key] = val;
    }
  }
  getConfig(key) {
    return this.attributes.configuration[key];
  }
  configHasKey(key) {
    return Object.prototype.hasOwnProperty.call(
      this.attributes.configuration,
      key
    );
  }
}

export class InstanceAssociationResource {
  constructor(options) {
    /** @type {String} */
    this.type = "instance-associations";
    this.attributes = {
      configuration: {
        /** @type {Array} */
        chosen: [],
      },
    };
    if (options.id != undefined) {
      this.id = options.id;
    }
    // TODO: Refactor this with methods instead of a bloated constructor with conditionals.
    if (options.relationships != undefined) {
      this.relationships = {
        sourceInstance: {
          data: {
            id: options.relationships.sourceInstance,
            type: "source-instances",
          },
        },
        widgetInstance: {
          data: {
            id: options.relationships.widgetInstance,
            type: "widget-instances",
          },
        },
        group: {
          data: {
            id: options.relationships.group,
            type: "groups",
          },
        },
      };
    }
  }
}

export class BoardResource extends BaseResource {
  constructor(options = {}) {
    super("boards", options);
  }
}

export class RuleResource extends BaseResource {
  constructor(options = {}) {
    super("rules", options);
  }
  setBoardRelationship(boardId) {
    this.setRelationship("board", { id: boardId, type: "boards" });
  }
  setSourceInstanceRelationship(sourceInstanceId) {
    this.setRelationship("board", {
      id: sourceInstanceId,
      type: "source-instances",
    });
  }
}
