<template>
  <div>
    <div v-if="backupUploadSuccess">
      <h4>{{ t("Success!") }}</h4>
      <p>
        {{
          t(
            "The backup was successfully uploaded and is being restored. Once finished, your glancr will reboot and attempt to connect to the network previously configured in your backup."
          )
        }}
      </p>
    </div>
    <form v-else id="restore_form" method="post" enctype="multipart/form-data">
      <p>
        {{
          t(
            "You can import your settings from a backup of your previous mirr.OS one installation."
          )
        }}
      </p>
      <fieldset>
        <input
          id="backup_file"
          type="file"
          name="backup_file"
          class="show-for-sr"
          v-validate
          required
          accept="application/x-gzip,application/gzip"
          @change="setSelectedFile"
        />
        <label for="backup_file" class="button expanded">
          {{ selectedFile || t("select backup file") }}
        </label>
      </fieldset>

      <button
        type="submit"
        class="button expanded"
        :disabled="formInvalid"
        @click.prevent="triggerRestoreFromBackup"
      >
        {{ backupUploading ? t("uploading ...") : t("start import") }}
      </button>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "BackupRestoreBlock",
  props: {
    errors: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
  },
  data: function () {
    return {
      selectedFile: "",
      backupUploading: false,
      backupUploadSuccess: null,
    };
  },
  computed: {
    formInvalid: function () {
      /* let errors = 0;
      for (let [, msg] of Object.entries(this.errors)) {
        if (msg.length > 0) errors++;
      }
      return errors > 0; */
      // FIXME: Safari somehow does not trigger the validation handler after input,
      return this.selectedFile.length === 0;
    },
  },
  methods: {
    setSelectedFile: function (e) {
      this.selectedFile =
        e.target.files[0] != undefined ? e.target.files[0].name : "";
    },
    triggerRestoreFromBackup: async function (e) {
      let fd = new FormData(e.target.form);
      fd.append("reference_time", Date.now());
      try {
        this.backupUploading = true;
        await axios.post(`/system/restore_backup`, fd);
        this.backupUploadSuccess = true;
        // FIXME: Workaround to hide the back button once restore is running to prevent additional input that might cause inconsistencies.
        document.getElementsByClassName("button--back")[0].remove();
      } catch (error) {
        this.backupUploadSuccess = false;
      } finally {
        this.backupUploading = false;
      }
    },
  },
  beforeDestroy: function () {
    this.$emit("clear-validations", {
      block: this.$vnode.componentOptions.tag,
    });
  },
};
</script>

<style lang="scss" scoped>
#restore_form {
  display: flex;
  flex-direction: column;
}
</style>
